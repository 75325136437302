import React, { useRef } from "react";
import "./Widgets.scss"
import { render } from "@testing-library/react";
import WidgetModal from "./WidgetModal"
import axios from '../../axios';
import { useState, useEffect } from "react";
import ViewModal from "./viewWidget/ViewWidget"
import DeleteWidget from "./deleteWidget/DeleteWidget";
import CopyWidget from "./copyWidget/CopyWidget"
import { toast } from "react-toastify";
import userEvent from "@testing-library/user-event";
const Widgets = (props) => {

    const [widgets, setWidgets] = useState([]);

    ////////////////////////////////////////////////   
    ///////  State Hooks per hapjen dhe /////////// 
    ////////  manipulimit modalit ////////////////// 
    //////////////////////////////////////////////// 

    const [isOpen, setIsOpen] = useState(false);
    const [isCopyModal, setIsCopyModal] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [locations, setLocations] = useState([]);
    const [widgetLang, setWidgetLang] = useState([]);

    ////////////////////////////////////////////////   
    ////////  Kontrollimi i hapjes te modalit //////
    ///////////////////////////////////////////////   
    // console.log('widgetLang', widgetLang)
    widgets.forEach((el) => {
        // console.log('widgetmapEl', el)
        const htmlString = el?.content;
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(htmlString, 'text/html');
        const iframe = parsedHtml.querySelector('iframe');
        if (iframe) {
            const iframeSrc = iframe.getAttribute('src');
            const widgetId = el.id;
            const selectedLang = iframeSrc.split('/')[3];
            const isWidgetLangExists = widgetLang.some((widget) => widget.widget_id === widgetId);
            if (!isWidgetLangExists) {
                setWidgetLang(oldstate => [...oldstate, { widget_id: widgetId, selected_lang: selectedLang }]);
            }
            // console.log('iframeSrcLang', widgetId, selectedLang);
        }
    });


    const renderViewModal = (data) => {
        const htmlString = data?.content;
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(htmlString, 'text/html');
        const iframe = parsedHtml.querySelector('iframe');

        if (iframe) {
            const iframeSrc = iframe.getAttribute('src');
            // console.log('iframeSrc', iframeSrc.split('/')[4])
            window.open(iframeSrc)
        }
    };
    const renderCopyModal = (data) => {
        // console.log('remderFnData', data);
        setIsCopyModal(true);
        setDataToModal(data);
    }
    const renderDeleteModal = (data) => {
        // console.log('remderFnData', data);
        setIsDeleteOpen(true);
        setDataToModal(data);
    }
    const getWidget = () => {
        axios.get("/api/admin/widgets/all")
            .then(data => {
                // console.log("surveys" , el.data)
                setWidgets(data.data)
            }).catch(err => {
                console.log('error', err)
            })
    }
    const getLocations = () => {
        axios.get("/api/admin/locations/all")
            .then((data) => {
                setLocations(data.data);
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    useEffect(() => {
        getWidget();
        getLocations();
    }, [])


    return (
        <>
            <WidgetModal getWidget={() => getWidget()} isOpen={isOpen} handleClose={() => setIsOpen(false)} />
            <ViewModal data={dataToModal} getWidget={() => getWidget()} isOpen={isViewOpen} handleClose={() => setIsViewOpen(false)} />
            <DeleteWidget data={dataToModal} getWidget={() => getWidget()} isOpen={isDeleteOpen} handleClose={() => setIsDeleteOpen(false)} />
            <CopyWidget data={dataToModal} getWidget={() => getWidget()} isOpen={isCopyModal} handleClose={() => setIsCopyModal(false)} />

            <div className="wrapper">
                <header className="mainpage-header">
                    <nav className="navbar pb-4 ps-4 main-items">
                        <div className="container">
                            <h5 className="text-light pt-3 main-title">WIDGETS-*MAIN* ADMINISTRATOR PORTAL</h5>
                            <button className="btn btn-sm-5 btn-secondary main-buttons" onClick={() => setIsOpen(true)}>Add WIDGET  < i className="fa ms-1 fa-circle-plus" /></button>
                        </div>
                    </nav>
                </header>
                <center>
                    <h5 className="pt-5 ">WIDGETS MANAGEMENT MODULE</h5>
                </center>
                {widgets === null ? <h1 className="mt-5">No Widgets Found In This Location</h1> :
                    <>

                        <div className="row rounded mt-5 border pb-2 pt-2 lista">
                            <div className="col-sm-1">ID</div>
                            <div className="col-sm-1">view <i className="fa-solid fa-eye"></i></div>
                            <div className="col-sm-1">copy <i className="fa-solid fa-copy"></i></div>
                            <div className="col-sm-2">delete <i className="fa fa-trash"></i></div>
                            <div className="col-sm-2">Language</div>
                            <div className="col-sm-3">Widget location</div>
                            <div className="col-sm-2">Type</div>
                        </div>

                        {widgets.map(el => {
                            // console.log("shaban gashi", widgetLang.find((el) =>  el.widget_id  ).selected_lang)
                            var selectedLanguage = widgetLang?.find(gjuha => gjuha?.widget_id == el?.id)?.selected_lang
                            return (
                                <div className="row rounded mt-3 border pt-2 pb-2 listcon">
                                    <div className="col-sm-1">{el.id}</div>
                                    <div className="col-sm-1 hoverViewEdit" role="button" onClick={() => renderViewModal(el)}>view <i className="fa-solid fa-eye"></i></div>
                                    <div className="col-sm-1 hoverViewEdit" role="button" onClick={() => renderCopyModal(el)}>copy <i className="fa-solid fa-copy"></i></div>
                                    <div className="col-sm-2 delete-location" role="button" onClick={() => renderDeleteModal(el)}>delete <i className="fa fa-trash"></i></div>
                                    <div className="col-sm-2 "><span>{selectedLanguage?.length > 2 ? "create new widget" : selectedLanguage} </span></div>
                                    <div className="col-sm-3">{locations.find(loc => loc.id === el.location_id)?.name_al}</div>
                                    <div className="col-sm-2">{el.type == 1 ? "Lokal" : "Global"}</div>
                                    
                                </div>
                            );
                        })}
                    </>
                }
            </div>
        </>
    );
}
export default Widgets;