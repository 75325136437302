import React from "react";
import "./AddUserModal.scss"
import { useState } from "react";
import axios from '../../axios';
import { toast } from "react-toastify";
import { useEffect } from "react";
import ReactPortal from "../portals/ReactPortal";
// ndryshimi i modalit nga const ne funkcion
// + parametrat prites si isOpen, handleClose, children te cilat ju kan jap ne Users.js

function AddUserModal({ getUsers, isOpen, handleClose }) {


    //////////// Control Esc per mbyllje te modalit
    useEffect(() => {
      const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
      document.body.addEventListener("keydown", closeOnEscapeKey);
      return () => {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
      };
    }, [handleClose]);
  
    
    // const [usermodal, setUsermodal] = useState(true);
    const [activeUser, setActiveUser] = useState(false);
    const [activeValue, setActiveValue] = useState(activeUser);
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState();
    const [locationGroups, setLocationGroups] = useState();
    const [locationGroup, setLocationGroup] = useState();
    const [groupId, setGroupId] = useState();
 

    const handlelocationID = (e) => {
        // console.log('roli', e.target.value)
        e.preventDefault()
        setLocationId(e.target.value)
    }
    const handleAssignedlocationID = (e) => {
        // console.log('lokacioni i selektuar', e.target.value)
        e.preventDefault();
        setLocationGroup(e.target.value)
    }
    const handleAssignedgroupID = (e) => {
        // console.log('groupID', e.target.value)
        e.preventDefault();
        setGroupId(e.target.value)
    }

    let activeValueQeShkon = () => {
        if (activeUser === false) {
            setActiveValue(1)
            setActiveUser(true)
        } else if (activeValue === 1) {
            setActiveUser(false)
            setActiveValue(0)
        };

    }
 
    const getLocationGroups = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then((data) => {
                setLocationGroups(data.data);
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    const getLocations = () => {
        axios.get("/api/admin/locations/all")
            .then((data) => {
                setLocations(data.data);
                // console.log('arton', data.data)

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    useEffect(() => {
        getLocationGroups();
        getLocations();
    }, [])

    const addUser = (e) => {
        e.preventDefault();
        // console.log('locationId', locationId , locationGroup)

        const datauser = {
            full_name: e?.target?.full_name?.value,
            email: e?.target?.email?.value,
            pass: e?.target?.password?.value,
            role: Number(e?.target?.role.value),
            active: activeValue,
            location_id: locationId && locationId === 2 || locationId === 3 ? locationGroup : groupId 
        }

        // console.log('hahahihi', locationId)
        axios.post("/api/admin/users/", datauser)
            .then(data => {
                // console.log("users added" + data.data)
                // setUsermodal(false);
                handleClose();
                getUsers();
                toast.success("User Added Sucesfully!")
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
    }

    // console.log('arton', locationId)

    // renderimi me isOpen state
    if (!isOpen) return null;
        return (
            <ReactPortal wrapperId="AddUserModal">
            <div className="adduser-modal fade-in">
                    <div onClick={()=>handleClose()} className="handleClose"></div>
                    <div className="adduser-content ">
                        <form onSubmit={addUser}>
                            <div className="adduser-header d-flex pb-2">
                                <h5 className="mx-auto pt-3">ADD NEW USER</h5>
                                <h5 className="x-btn pt-3 " onClick={()=>handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                            </div>
                            <div className="adduser-body " >
                                <h4 className=" mx-auto mt-2 col-11">User Name</h4>
                                <input required id="full_name" name="full_name" type="text" className="mb-2 InputtAdd-user col-11 " placeholder="e.g. johndoe" />
                                <h4 className=" mx-auto col-11">Email</h4>
                                <input required id="email" name="email" type="email" className=" mb-2 col-11 mx-auto InputtAdd-user" placeholder="admin@admin.com" />
                                <div className="d-block col-11 mx-auto mb-2">
                                    <h4 className="">Enter Password</h4>
                                    <div className="d-flex justify-content-between ">
                                        <input required id="pass" name="password" type="text" className=" col-9 password-input" placeholder="*******"  />
                                    </div>

                                </div>

                                <h5 className="form-check-label col-11 mx-auto" htmlFor="flexCheckDefault">
                                    Is Active :
                                    <input id="active" onClick={() => activeValueQeShkon(!activeUser)} className="form-check-input ms-2 mt-1" type="checkbox" />
                                </h5>

                                <div className="form-outline mx-auto col-11 ">
                                    <h4 className="d-flex left">Role</h4>
                                    <select required id="role" defaultValue={''} onChange={handlelocationID} name="role" className="form-select" aria-label="Default select example">
                                        <option disabled value={''} hidden>-- Please select a role --</option>
                                        <option value={1} >Location Admin (Local Admin)</option>
                                        <option value={2} >Mobile</option>
                                        <option value={3} >Tablet</option>
                                    </select>
                                </div>

                            </div>
                            <div className="form-outline mx-auto col-sm-11">
                                {locationId == 1 ?
                                    <>
                                        <h4 className="d-flex left">Assign a group to the user</h4>
                                        <select required defaultValue={''} onChange={handleAssignedgroupID} className="form-select selectDropDown" aria-label="Default select example">
                                            <option disabled value={''} hidden>-- Please select a group --</option>
                                            {
                                                locationGroups?.map(el => {
                                                    // console.log('el',el)
                                                    return (<option value={el.id}>{el.name_al}</option>
                                                    );
                                                })
                                            }

                                        </select>
                                    </>
                                    :
                                    locationId == 3 || 2 && locationId != undefined ?
                                        <>
                                            <h4 className="d-flex left">Assign a location to the user</h4>
                                            <select required defaultValue={''} onChange={handleAssignedlocationID} className="form-select selectDropDown" aria-label="Default select example">
                                                <option disabled value={''} hidden>-- Please select a location --</option>
                                                {
                                                    locations?.map(el => {
                                                        // console.log('el',el)
                                                        return (<option value={el.id}>{el.name_al}</option>
                                                        );
                                                    })
                                                }

                                            </select>
                                        </>
                                        :
                                      <>
                                       <h4 className="d-flex left">Assign a location to the user</h4>
                                       {/* <select required defaultValue={''} onChange={handleAssignedlocationID} className="form-select selectDropDown" aria-label="Default select example">
                                                <option disabled value={''}>-- Please select a user Role ! --</option>

                                            </select> */}
                                      </>
                                }

                                <i style={{ color: "red" }}>Give acces to this user to one of the services / locations. <br />
                                    The user will only be able to edit the survey to the assigned location.
                                </i>
                            </div>
                            <div className="pt-2 pb-2">
                                <button type="submit"  className="btn d-flex mx-auto adduser-btn">SAVE/UPDATE</button>
                            </div>

                        </form>
                    </div>
                </div>

            </ReactPortal>
        );
}
export default AddUserModal;