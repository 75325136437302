import React from "react";
import "./editSurvey.scss"
import axios from '../../../axios';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../loader/loader";
import ReactPortal from "../../portals/ReactPortal";

function EditSurvey({ props, isOpen, handleClose }) {
    // console.log('EditSurvey', props)
    const [ViewModalOpen, setViewModalOpen] = useState(true)
    const [questions, setQuestions] = useState([]);
    const [surveyName, setSurveyName] = useState([]);
    const [editTrigger, setEditTrigger] = useState('');
    const [deleteTrigger, setDeleteTrigger] = useState('');
    const [shoudLoad, setShouldLoad] = useState(true);
    const [editOptTrigger, setEditOptTrigger] = useState(false)
    const [deleteOptTrigger, setDeleteOptTrigger] = useState(false);
    const [shoudShowAddQuestion, setShoudShowAddQuestion] = useState(true);
    const [shoudShowAddOption, setShoudShowAddOption] = useState(true);



    const LOCATION_ID = window.localStorage.getItem('location_id');

    const getSurveyData = () => {
        axios
            .get(`/api/admin/surveys/surveyQuestions/${props?.location_id}/${props?.id}`)
            .then((data) => {
                setQuestions(data.data);

                // console.log('questionsArton', data.data[0]?.survey_id)
                if (questions !== []) {
                    setShouldLoad(false);
                }
                // console.log('amujte', data.data)
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while getting the Survey');
            });
    }

    const submitQuestionData = async (e, el, index) => {
        // console.log('e', e)
        // console.log('el', el)
        // console.log('index', index)
        let changedQuestionData = ({
            id: el.id,
            survey_id: props?.id,
            question_al: el.question_al,
            question_en: el.question_en,
            question_sr: el.question_sr,
            question_tr: el.question_tr
        });


        await axios
            .put("/api/admin/survey-questions", changedQuestionData)
            .then((res) => {
                // console.log(res)
                toast.success('Survey edited sucessfully!');
                setEditTrigger(null);
                getSurveyData();
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while registering the Survey');
            });

    }

    // edit option 
    const submitOptionData = async (e, el, index) => {
        // console.log('e', e)
        // console.log('el qikjo o daj a po vjen', el)
        // console.log('index', index)
        let changedOptionData = ({
            id: el.id,
            question_id: el.question_id,
            survey_id: el.survey_id,
            option_al: el.option_al,
            option_en: el.option_en,
            option_sr: el.option_sr,
            option_tr: el.option_tr
        });


        await axios
            .put("/api/admin/survey-question-options/edit-options", changedOptionData)
            .then((res) => {
                // console.log('editoptionres', res);
                setEditOptTrigger(false);
                toast.success('Survey option edited sucessfully!');

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while registering the Survey');
            });

    }

    // Delete Question
    const handleDeleteQuestion = async (deleteOptionID) => {
        // console.log('deleteOptionID', deleteOptionID)
        let deleteStatus = {
            is_deleted: true
        }
        await axios
            .put(`/api/admin/survey-questions/delete/${deleteOptionID}`, deleteStatus)
            .then((res) => {
                // console.log('editoptionres', res);
                // setEditOptTrigger(false);
                setEditTrigger(null);
                getSurveyData();
                toast.success('Question deleted sucessfully!');

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while deleting the question!');
            });
    }
    const addNewQuestionExcSurvey = async (deleteOptionID) => {
        // console.log('deleteOptionID', deleteOptionID)
        let newQuestion = questions[questions.length - 1];
        let newQuestionBody = {
            survey_id: props?.id,
            is_deleted: 0,
            question_al: newQuestion.question_al,
            question_en: newQuestion.question_en,
            question_sr: newQuestion.question_sr,
            question_tr: newQuestion.question_tr
        }
        // console.log('newQuestionBody', newQuestionBody)
        await axios
            .post(`/api/admin/survey-questions`, newQuestionBody)
            .then((res) => {
                // console.log('editoptionres', res);
                // setEditOptTrigger(false);
                // setEditTrigger(null);
                setShoudShowAddQuestion(true);
                setShouldLoad(true);
                getSurveyData();
                // getSurveyData();
                toast.success('New question created sucessfully!');

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while deleting the question!');
            });
    }
    // console.log('artonOMRET', props)


    //////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////DELETE OPTION////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////
    const handleDeleteOption = async (OptionID) => {
        // console.log('deleteOptionID', OptionID)
        let deleteStatus = {
            is_deleted: true
        }
        await axios
            .put(`/api/admin/survey-question-options/delete/${OptionID}`, deleteStatus)
            .then((res) => {
                // console.log('editoptionres', res);
                // setEditOptTrigger(false);
                setEditTrigger(null);
                getSurveyData();
                toast.success('option deleted sucessfully!');

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while deleting the question!');
            });
    }


    //////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////ADD NEW OPTION////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////

    const addNewOptionExcquestion = async (optionData, Qel) => {
        // console.log("new Option", optionData)
        setShoudShowAddOption(true)
        let newQuestion = Qel.options;
        // console.log('newOptionBody123', Qel)
        let newOptionBody = {
            survey_id: props?.id,
            question_id: Qel?.id,
            option_al: Qel?.options[Qel?.options?.length - 1]?.option_al,
            option_en: Qel?.options[Qel?.options?.length - 1]?.option_en,
            option_sr: Qel?.options[Qel?.options?.length - 1]?.option_sr,
            option_tr: Qel?.options[Qel?.options?.length - 1]?.option_tr
        }
        // console.log('newOptionBody', Qel)
        await axios
            .post(`/api/admin/survey-question-options`, newOptionBody)
            .then((res) => {
                // console.log('editoptionres', res);
                setShouldShowAddOption(true);
                // setEditOptTrigger(false);
                // setEditTrigger(null);
                setShoudShowAddQuestion(true);
                setShouldLoad(true);
                getSurveyData();
                // getSurveyData();
                toast.success('New option created sucessfully!');

            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while saving the option!');
            });
    }

    //HANDLE SURVEY NAME
    const emriPytesoritObj = useState({ name_al: `${props?.nameAL}`, name_en: `${props?.nameEN}`, name_sr: `${props?.nameSR}`, name_tr: `${props?.nameTR}`, options: `${props?.options}`, location_id: 9999, isDeleted: 0 })
    const handleSurvey = (e, lang) => {
        setSurveyName(emriPytesoritObj);
        e.preventDefault();
        let currentState = emriPytesoritObj;
        if (lang === 'al') {
            let assignal = Object.assign(currentState);
            assignal[0].name_al = e.target.value;
        }
        if (lang === 'en') {
            let assignen = Object.assign(currentState);
            assignen[0].name_en = e.target.value;
        }
        if (lang === 'sr') {
            let assignsr = Object.assign(currentState);
            assignsr[0].name_sr = e.target.value;
        }
        if (lang === 'tr') {
            let assigntr = Object.assign(currentState);
            assigntr[0].name_tr = e.target.value;
        }

    };

    // SET QUESTION
    const addQuestions = () => {
        setQuestions(oldstate => [...oldstate, { survey_id: props?.id, question_al: "", question_en: "", question_sr: "", question_tr: "", options: [] }]);
        setSurveyName(emriPytesoritObj);
        setShoudShowAddQuestion(false);
        // console.log("op123", questions)

    }
    const handleQuestion = (e, index, lang) => {
        e.preventDefault();
        // console.log('e', e)
        let currentState = questions;
        if (lang === 'al') {
            let assignal = Object.assign({}, currentState);
            assignal[index].question_al = e.target.value;
        }
        if (lang === 'en') {
            let assignen = Object.assign({}, currentState);
            assignen[index].question_en = e.target.value;
        }
        if (lang === 'sr') {
            let assignsr = Object.assign({}, currentState);
            assignsr[index].question_sr = e.target.value;
        }
        if (lang === 'tr') {
            let assigntr = Object.assign({}, currentState);
            assigntr[index].question_tr = e.target.value;
        }
    };


    //SET OPTION
    const [option, setOption] = useState([]);
    const [shouldShowAddOption, setShouldShowAddOption] = useState(true);
    const addOptions = (e, pindex, data) => {
        // console.log('shaban gashi serfte e, pindex , data', e, pindex, data)
        setShouldShowAddOption(false);
        e.preventDefault();
        // console.log('indexOption', pindex);
        setOption(data.options.length);
        let myObje = { option_al: "", option_en: "", option_sr: "", option_tr: "" };
        let currentState = questions;
        // console.log('arton', currentState[pindex])
        // console.log('shaban gashi, data, e', pindex, data, e);
        setQuestions(currentState[pindex].options.push(myObje))
        setQuestions(currentState);
    }

    const handleOption = (e, pindex, cindex, type) => {
        // console.log('e.target', e.target.value)
        // console.log('type', type)
        // console.log('cindex', cindex)
        // console.log('pindex', pindex);
        e.preventDefault();
        // let myObje = { option_al: "", option_en: "", option_sr: "", option_tr: "" };
        let currentState = questions;
        let assign = Object.assign({}, currentState);
        // console.log('optionArray', currentState);
        // console.log('option', initialState);
        if (type === 'oal') {
            assign[pindex].options[cindex].option_al = e.target.value;
        }
        if (type === 'osr') {
            assign[pindex].options[cindex].option_sr = e.target.value;
        }
        if (type === 'oen') {
            assign[pindex].options[cindex].option_en = e.target.value;
        }
        if (type === 'otr') {
            assign[pindex].options[cindex].option_tr = e.target.value;
        }
        setQuestions(currentState);
    }

    useEffect(() => {
        getSurveyData();
    }, [props])

    const handleCloseModal = () => {
        setViewModalOpen(false);
    }
    const handleRemoveOption = (el, qel) => {
        if (qel?.options[qel.options.length - 1]?.id === undefined) { qel?.options.splice(-1) }
        setShouldShowAddOption(true);
    }
    const cancelNewQuestion = () => {
     
        questions.pop();
        setShoudShowAddQuestion(true);
    }


    //////////// Control Esc per mbyllje te modalit

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    // const g = document.getElementById();
    // for (const i = 0, len = g.children.length; i < len; i++)

    if (!isOpen) return null;
    return (
        <ReactPortal wrapperId="EditSurveyModal">
            <div className="handleClose" onClick={() => handleClose()}></div>
            <div className="ViewSurvey-header fade-in ">
                <h5 className="text-light col-sm HeaderTekst">EDIT "{props?.name_al}" SURVEY</h5>
                <h5 className="text-light iksiHeader " role="button" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
            </div>
            <div className="wrapper ViewSurvey fade-in">
                <div className="fullModal pb-2">
                    <div className="mx-auto row pb-3 pt-3" style={{ backgroundColor: "#0ca88921" }}>
                        <div className="container d-flex surveyName">
                            <div className={editTrigger === true ? " col-sm-2 mt-4 shadowEnable" : " col-sm-2 mt-4 shadowDisable"} >
                                <p className="d-flex left" style={{ fontWeight: "bold" }}>Shqip</p>
                                <input disabled onChange={(e) => handleSurvey(e, 'al')} className="emriPytsorit form-control" style={{backgroundColor:"#e9ecef"}} type="text" defaultValue={props?.name_al}></input>
                            </div>
                            <div className={editTrigger === true ? " col-sm-2 mt-4 shadowEnable" : " col-sm-2 mt-4 shadowDisable"}>
                                <p className="d-flex left" style={{ fontWeight: "bold" }}>English</p>
                                <input disabled onChange={(e) => handleSurvey(e, 'en')} className="emriPytsorit form-control" style={{backgroundColor:"#e9ecef"}} type="text" defaultValue={props?.name_en}></input>
                            </div>
                            <div className={editTrigger === true ? " col-sm-2 mt-4 shadowEnable" : " col-sm-2 mt-4 shadowDisable"}>
                                <p className="d-flex left" style={{ fontWeight: "bold" }}>Serbian</p>
                                <input disabled onChange={(e) => handleSurvey(e, 'sr')} className="emriPytsorit form-control" style={{backgroundColor:"#e9ecef"}} type="text" defaultValue={props?.name_sr}></input>
                            </div>
                            <div className={editTrigger === true ? " col-sm-2 mt-4 pb-3 shadowEnable" : " col-sm-2 mt-4 pb-3 shadowDisable"}>
                                <p className="d-flex left" style={{ fontWeight: "bold" }}>Turkish</p>
                                <input disabled onChange={(e) => handleSurvey(e, 'tr')} className="emriPytsorit form-control" style={{backgroundColor:"#e9ecef"}} type="text" defaultValue={props?.name_tr}></input>
                            </div>
                            {/* <div className="buttoniEdit col mt-5 d-flex mx-auto">
                            <i className="fa fa-edit"></i>
                        </div>
                        <div className="buttoniDelete col mt-5 d-flex mx-auto">
                            <i className="fa fa-trash"></i>
                        </div> */}
                        </div>
                    </div>

                    {questions.length === 0 ? <Loader /> : questions && questions?.map((Qel, index) => {
                        // console.log('el222', Qel, index)
                        return (
                            <>
                                {Qel?.id === undefined ?
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 row pt-5">
                                                <h3 className="col-sm-6 d-flex row left ms-1">Question #{index + 1}</h3>

                                                <div role="button" className="col-sm-6 d-flex gap-2 optionsButtons">
                                                    {/* {console.log('id - index - deleteTrgier, editTriger', Qel.id, index, deleteTrigger, editTrigger)} */}
                                                    {Qel?.id === undefined ?
                                                        <>{
                                                            // console.log('qel2', Qel)
                                                        }
                                                            <button onClick={(e) => { e.preventDefault(); addNewQuestionExcSurvey() }} className="btn btn-sm btn-success d-flex mb-4">Save question</button>
                                                            <button onClick={(e) => { e.preventDefault(); cancelNewQuestion() }} className="btn btn-sm btn-danger mb-4">Cancel question </button>
                                                        </>
                                                        :
                                                        <>
                                                            {deleteTrigger && deleteTrigger === index ?
                                                                <>
                                                                    <p role="button" style={{ color: "#0ca788" }} onClick={() => handleDeleteQuestion(index)}>Delete</p>
                                                                    <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }} />
                                                                    <p style={{ color: "red" }} role="button" onClick={() => setDeleteTrigger(index === null)}>Cancel</p>
                                                                    <i onClick={() => setDeleteTrigger(index === null)} className="fa-solid fa-x pt-1" style={{ color: "red" }} />
                                                                </>
                                                                :
                                                                <>
                                                                    {/* {console.log('firstindex', index)} */}
                                                                    {/* {console.log('editTrigger', editTrigger)} */}

                                                                    <p style={{ color: "#0ca788" }} onClick={(e) => { e.preventDefault(); setEditTrigger(index) }}>Edit question</p>
                                                                    <i className="pt-1 fa-solid fa-pen" />
                                                                </>
                                                            }
                                                            {editTrigger && editTrigger === index ?
                                                                <>
                                                                    <p role="button" style={{ color: "#0ca788" }} onClick={(e) => submitQuestionData(e, Qel, index)}>Confirm</p>
                                                                    <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }}></i>
                                                                    <p style={{ color: "red" }} role="button" onClick={() => setEditTrigger(index === null)}>Cancel</p>
                                                                    <i onClick={() => setEditTrigger(index === null)} className="fa-solid fa-x pt-1" style={{ color: "red" }} />
                                                                </>
                                                                :
                                                                <>
                                                                    <p role="button" style={{ color: "red" }} onClick={() => setDeleteTrigger(index)} >Delete question </p>
                                                                    <i className="pt-1 fa-solid fa-trash"></i>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className=" gap-3 d-flex pb-5"   >
                                                <div className={editTrigger === index ? " col-sm shadowDisable" : " col-sm shadowEnable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Albanian</p>
                                                        <textarea style={{ color: "grey" }} className="Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'al')}
                                                            defaultValue={Qel.question_al}></textarea>
                                                    </div>
                                                </div>
                                                <div className={editTrigger === index ? " col-sm shadowDisable" : " col-sm shadowEnable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">English</p>
                                                        <textarea style={{ color: "grey" }} className=" Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'en')}
                                                            defaultValue={Qel.question_en}></textarea>
                                                    </div>
                                                </div>
                                                <div className={editTrigger === index ? " col-sm shadowDisable" : " col-sm shadowEnable"}  >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Serbian</p>
                                                        <textarea style={{ color: "grey" }} className=" Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'sr')}
                                                            defaultValue={Qel.question_sr}></textarea>
                                                    </div>
                                                </div>
                                                <div className={editTrigger === index ? " col-sm shadowDisable" : "col-sm shadowEnable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Turkish</p>
                                                        <textarea style={{ color: "grey" }} className="Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'tr')}
                                                            defaultValue={Qel.question_tr}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 row pt-5">
                                                <h3 className="col-sm-7 d-flex row left ms-1">Question #{index + 1}</h3>
                                                <div className="col-sm-2 d-flex">
                                                    {shouldShowAddOption === true ?
                                                        <button onClick={(e) => addOptions(e, index, Qel)} className="btn btn-sm btn-success mb-3">Add Options for Question {index + 1}</button>
                                                        :
                                                        ""}
                                                </div>
                                                <div role="button" className="col-sm-3 d-flex gap-2 optionsButtons">
                                                    {/* {console.log('id - index - deleteTrgier, editTriger', Qel.id, index, deleteTrigger, editTrigger)} */}

                                                    {deleteTrigger && deleteTrigger === Qel.id ?
                                                        <>
                                                            <p role="button" style={{ color: "#0ca788" }} onClick={() => handleDeleteQuestion(Qel.id)}>Delete</p>
                                                            <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }} />
                                                            <p style={{ color: "red" }} role="button" onClick={() => setDeleteTrigger(Qel.id === null)}>Cancel</p>
                                                            <i onClick={() => setDeleteTrigger(Qel.id === null)} className="fa-solid fa-x pt-1" style={{ color: "red" }} />
                                                        </>
                                                        :
                                                        <>
                                                            {/* {console.log('firstindex', index)} */}
                                                            {/* {console.log('editTrigger', editTrigger)} */}

                                                            <p style={{ color: "#0ca788" }} onClick={(e) => { e.preventDefault(); setEditTrigger(Qel.id) }}>Edit question</p>
                                                            <i className="pt-1 fa-solid fa-pen" />
                                                        </>
                                                    }
                                                    {editTrigger && editTrigger === Qel.id ?
                                                        <>
                                                            <p role="button" style={{ color: "#0ca788" }} onClick={(e) => submitQuestionData(e, Qel, index)}>Confirm</p>
                                                            <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }}></i>
                                                            <p style={{ color: "red" }} role="button" onClick={() => setEditTrigger(Qel.id === null)}>Cancel</p>
                                                            <i onClick={() => setEditTrigger(Qel.id === null)} className="fa-solid fa-x pt-1" style={{ color: "red" }} />
                                                        </>
                                                        :
                                                        <>
                                                            <p role="button" style={{ color: "red" }} onClick={() => setDeleteTrigger(Qel.id)} >Delete question </p>
                                                            <i className="pt-1 fa-solid fa-trash"></i>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <hr />
                                            <div className=" gap-3 d-flex pb-5"   >
                                                {/* {deleteTrigger === true ? <p className="doYouReallyWantToDelete">Are you sure you want to delete this question?</p>: ''} */}

                                                <div className={editTrigger === Qel.id ? " col-sm shadowEnable" : " col-sm shadowDisable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Albanian</p>
                                                        <textarea disabled={editTrigger === Qel.id ? "" : "disabled"} style={{ color: "grey" }} className="Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'al')}
                                                            defaultValue={Qel.question_al}></textarea>
                                                    </div>
                                                    {Qel?.options && Qel?.options?.map((el, cindex) => {
                                                        return (
                                                            el?.id !== undefined ?
                                                                <>
                                                                    <hr className="HrDashStyle" />
                                                                    <input disabled={(editOptTrigger === el.id) ? "" : "disabled"} type="text" className={editOptTrigger === el.id ? "form-control mt-5" : "form-control mt-5 optionbg"} placeholder="Albanian" defaultValue={el.option_al} onChange={(e) => handleOption(e, index, cindex, 'oal')} />
                                                                </>
                                                                :
                                                                <>
                                                                    <hr className="HrDashStyle" />
                                                                    <input type="text" className={editOptTrigger === el.id ? "form-control mt-5" : "form-control mt-5"} placeholder="Albanian" onChange={(e) => handleOption(e, index, cindex, 'oal')} /></>
                                                        )
                                                    })
                                                    }
                                                    <div>
                                                    </div>
                                                </div>
                                                <div className={editTrigger === Qel.id ? " col-sm shadowEnable" : " col-sm shadowDisable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">English</p>
                                                        <textarea disabled={editTrigger === Qel.id ? "" : "disabled"} style={{ color: "grey" }} className=" Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'en')}
                                                            defaultValue={Qel.question_en}></textarea>
                                                    </div>


                                                    {
                                                        Qel?.options && Qel?.options?.map((el, cindex) => {
                                                            return (
                                                                el?.id !== undefined ?
                                                                    <>
                                                                        <hr className="HrDashStyle" />
                                                                        <input disabled={(editOptTrigger === el.id) ? "" : "disabled"} type="text" className={editOptTrigger === el.id ? "form-control mt-5" : "form-control mt-5 optionbg"} placeholder="English" defaultValue={el.option_en} onChange={(e) => handleOption(e, index, cindex, 'oen')} />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <hr className="HrDashStyle" />
                                                                        <input type="text" className={editOptTrigger === el.id ? "form-control mt-5" : "form-control mt-5"} placeholder="English" defaultValue={el.option_en} onChange={(e) => handleOption(e, index, cindex, 'oen')} />
                                                                    </>
                                                            )
                                                        })

                                                    }
                                                    {/* <input disabled type="text" className="form-control mt-3" placeholder="test" onChange={(e) => handleOption(e, 'en')}/> */}
                                                </div>
                                                <div className={editTrigger === Qel.id ? " col-sm shadowEnable" : " col-sm shadowDisable"}  >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Serbian</p>
                                                        <textarea disabled={editTrigger === Qel.id ? "" : "disabled"} style={{ color: "grey" }} className=" Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'sr')}
                                                            defaultValue={Qel.question_sr}></textarea>
                                                    </div>

                                                    {Qel?.options && Qel?.options?.map((el, cindex) => {
                                                        return (
                                                            el?.id !== undefined ?
                                                                <>
                                                                    <hr className="HrDashStyle" />
                                                                    <input disabled={(editOptTrigger === el.id) ? "" : "disabled"} type="text" className={editOptTrigger === el.id ? "form-control mt-5" : "form-control mt-5 optionbg"} placeholder="Serbian" defaultValue={el.option_sr} onChange={(e) => handleOption(e, index, cindex, 'osr')} />
                                                                </>
                                                                :
                                                                <>
                                                                    <hr className="HrDashStyle" />
                                                                    <input type="text" className={editOptTrigger === el.id ? "form-control mt-5" : "form-control mt-5 "} placeholder="Serbian" defaultValue={el.option_sr} onChange={(e) => handleOption(e, index, cindex, 'osr')} />
                                                                </>
                                                        )
                                                    }
                                                    )}
                                                </div>
                                                <div className={editTrigger === Qel.id ? " col-sm shadowEnable" : "col-sm shadowDisable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Turkish</p>

                                                        <textarea disabled={(editTrigger === Qel.id) ? "" : "disabled"} style={{ color: "grey" }} className="Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'tr')}
                                                            defaultValue={Qel.question_tr}></textarea>
                                                    </div>

                                                    {Qel?.options && Qel?.options?.map((el, cindex) => {
                                                        return (<>
                                                            <hr className="HrDashStyle" />
                                                            <div className="d-flex optionsButtons ">
                                                                {/* {console.log('cindex',)} */}
                                                                {/* {console.log('option el jasht', el, cindex)} */}
                                                                {el.id === undefined ?
                                                                    <>
                                                                        {/* <div className="d-flex gap-2">
                                                                 <p role="button" style={{ color: "#0ca788" }} className="EditOptionButton" onClick={(e) => { e.preventDefault(); addNewQuestionExcSurvey() }}>Save option  <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }}/></p>
                                                                 <p role="button" className="EditOptionButton" style={{ color: "red" }}>Cancel option  <i onClick={() => setDeleteOptTrigger(el.id)} className="fa-solid fa-x pt-1" style={{ color: "red" }} /></p>
                                                                </div> */}
                                                                        <div className="mx-auto d-flex gap-4">
                                                                            <button onClick={(e) => { e.preventDefault(); addNewOptionExcquestion(e, Qel) }} className="OptionBTN-text btn btn-sm btn-success col-sm-6 d-flex" style={{ height: "25px" }}>Save option</button>
                                                                            <button onClick={(e) => { e.preventDefault(); handleRemoveOption(e, Qel) }} className="OptionBTN-text btn btn-sm col-sm-6 btn-danger" style={{ height: "25px" }}>Cancel option </button>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {deleteOptTrigger && deleteOptTrigger === el.id ?
                                                                            <>
                                                                                {/* {console.log('option el mrena1', el)} */}
                                                                                <div className="mx-auto d-flex gap-3">
                                                                                    <p role="button" style={{ color: "#0ca788" }} className="EditOptionButton" onClick={() => handleDeleteOption(el.id)}>Delete
                                                                                        <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }} />
                                                                                    </p>
                                                                                    <p style={{ color: "red" }} role="button" className="EditOptionButton" onClick={() => setDeleteOptTrigger(el.id === null)}>Cancel</p>
                                                                                    <i onClick={() => setDeleteOptTrigger(el.id === null)} className="fa-solid fa-x pt-1" style={{ color: "red" }} />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {/* {console.log('firstindex', index)} */}
                                                                                <div className="mx-auto d-flex gap-3">
                                                                                    <p role="button" style={{ color: "#0ca788" }} className="EditOptionButton" onClick={() => setEditOptTrigger(el.id)}>Edit option <i className="pt-1 fa-solid fa-pen" />
                                                                                    </p>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {/* {console.log('editOptTrigger', editOptTrigger)} */}
                                                                        {editOptTrigger && editOptTrigger === el.id ?
                                                                            <>
                                                                                {/* {console.log('option el mrena', el)} */}
                                                                                <div className="mx-auto d-flex gap-3">
                                                                                    <p role="button" style={{ color: "#0ca788" }} onClick={(e) => submitOptionData(e, el, index)} className="EditOptionButton">Confirm</p>
                                                                                    <i className="fa-solid fa-check pt-1" style={{ color: "#0ca788" }}></i>
                                                                                    <p style={{ color: "red" }} role="button" className="EditOptionButton" onClick={() => setEditOptTrigger(el.id === null)}>Cancel</p>
                                                                                    <i onClick={() => setEditOptTrigger(el.id === null)} className="fa-solid fa-x pt-1" style={{ color: "red" }} />
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <p role="button" className="DeleteOptionButton" style={{ color: "red" }} onClick={() => setDeleteOptTrigger(el.id)} >delete option</p>
                                                                                <i className="pt-1 fa-solid fa-trash"></i>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            {/* {console.log('shabanGashiTesimtEl', el)} */}
                                                            {el?.id !== undefined ?

                                                                <input disabled={(editOptTrigger === el.id) ? "" : "disabled"} defaultValue={el.option_tr} type="text" className={editOptTrigger === el.id ? "form-control mt-1" : "form-control mt-1 optionbg"} placeholder="Turkish" onChange={(e) => handleOption(e, index, cindex, 'otr')} />
                                                                :
                                                                <input className="form-control mt-1" type="text" placeholder="Turkish" onChange={(e) => handleOption(e, index, cindex, 'otr')} />
                                                            }
                                                        </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                            </>
                        )
                    })
                    }
                    {shoudLoad === true ? "" : <div className=" buttonatEdit d-block ">
                        {shoudShowAddQuestion === true ?
                            <button className="btn btn-secondary mt-5 btn-success btn-lg addNewBtn" onClick={addQuestions}>Add New Question</button>
                            :
                            ""
                        }

                    </div>}

                </div>


                {/* <button onClick={submitData}>hello post</button> */}
            </div>
        </ReactPortal>
    );
}
export default EditSurvey;