import './App.scss';
import { Routes, Route, BrowserRouter, Navigate, NavLink, Link } from "react-router-dom";
import React from 'react';
import Signin from "./components/signIn/Signin"
import Mainpage from "./components/mainPage/Mainpage"
import Services from './components/servicesLocations/ServicesAndLocations';
import Surveys from './components/surveys/Surveys';
import AddSurvey from './components/surveys/addSurvey/AddSurvey';
import Widgets from './components/widgets/Widgets';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddLocation from './components/servicesLocations/addLocation/addLocation';
import setAuth from './setAuth';
import Users from './components/userSettings/Users';
import SurveyAnswers from './components/surveyAnswers/surveyAnswers';


function App() {
  let loggedInStatus = window.localStorage.getItem('logged_in');
  const location_id = window.localStorage.getItem('location_id');

  return (
    <div className="App">
      <ToastContainer position="bottom-right" theme="colored" style={{ zIndex: '9999' }} />
      <BrowserRouter>
        <div className="pages">

          <Routes>

            {loggedInStatus === "true" && setAuth() ? (
              <>
                {location_id == 9999 ?
                  <>
                    <Route path="/" element={setAuth() ? <Mainpage /> : <Signin />} />
                    <Route path="/services" element={setAuth() ? <Services /> : <Signin />} />
                    <Route path="/surveys" element={setAuth() ? <Surveys /> : <Signin />} />
                    <Route path='/widgets' element={setAuth() ? <Widgets /> : <Signin />} />
                    <Route path='/addSurvey/:id/:gid' element={setAuth() ? <AddSurvey /> : <Signin />} />
                    <Route path='/addLocation' element={setAuth() ? <AddLocation /> : <Signin />} />
                    <Route path='/users' element={setAuth() ? <Users /> : <Signin />} />
                    <Route path='/surveyAnswers' element={setAuth() ? <SurveyAnswers /> : <Signin />} />
                  </>
                  :
                  <>
                    {
                      window.location.pathname != '/' && window.location.pathname != '/surveys'
                        ?
                        window.location.assign('/')
                        :
                        <>
                          <Route path="/" element={setAuth() ? <Mainpage /> : <Signin />} />
                          <Route path="/surveys" element={setAuth() ? <Surveys /> : <Signin />} />
                          <Route path='/surveyAnswers' element={setAuth() ? <SurveyAnswers /> : <Signin />} />
                        </>
                    }

                  </>

                }

              </>
            ) : (
              <Route path="/" element={<Signin />} />
            )}
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
