import React from "react";
import { useState, useEffect } from "react";
import axios from '../../axios';
import { toast } from "react-toastify";
import ReactPortal from "../portals/ReactPortal";


// const DeleteLocation = (props) => {
function DeleteLocation({ props, isOpen, handleClose }) {
    // console.log('DeleteProps',props)
    //////////// Control Esc per mbyllje te modalit
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);


    const [deletemodal, setDeletemodal] = useState(true)
    const handleDelete = () => {
        axios.delete(`/api/admin/locations/${props}`)
            .then(data => {
                // console.log("delete location" , data.props)
                handleClose();
                toast.success("Location deleted sucessfully!")
            }).catch(err => {
                console.log("error", err)
                toast.error("Something went wrong deleting location.")
            })

    }
    if (isOpen === true) {
        return (
            <ReactPortal>
                    <div className="delete-user-modal fade-in">
                <div className="wrapper handleClose" onClick={() => handleClose()}>
                </div>
                        <div className="delete-user-content">
                            <div className="delete-user-header d-flex">
                                <h5 className="pt-3 mx-auto">Delete Location</h5>
                                <h5 className="xwiget-btn pt-3" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                            </div>
                            <form >

                                <h4 className=" pt-5 d-flex justify-content-center mx-auto tekstiNdelete ">Are you sure you want to delete this location?</h4>

                                <div className="d-flex mx-auto justify-content-center delete-user-btn pb-5">
                                    <a className="btn btn-success butonatDelete" onClick={() => handleDelete()}>Yes</a>
                                    <button className="btn btn-danger butonatDelete ms-2" onClick={() => handleClose()}>No</button>
                                </div>

                            </form>
                        </div>
                    </div>
            </ReactPortal>
        );
    } else return null;
}
export default DeleteLocation;