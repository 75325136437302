import React from "react";
import "../servicesLocations/Services.scss"
import { render } from "@testing-library/react";
import { useState, useEffect } from "react";
import axios from '../../axios';
import AddLocation from "../servicesLocations/addLocation/addLocation";
import { toast } from "react-toastify";
import EditLocation from './editLocation/editLocation'
import ViewLocation from "./viewLocation/viewLocation";
import DeleteLocation from "./DeleteLocation";
import AddGroupLocationModal from "./addgroup/AddGroupLocation";
import EditGroupLocationModal from "./editgroup/EditLocationGroup";
import DeleteGroupLocation from "./deletegroup/DeleteLocationGroup";


const Services = () => {
    const [locations, setLocations] = useState()
    const [groupLocations, setGroupLocations] = useState([])
    const [showTab, setShowTab] = useState(true);
    // const [shouldLoad, setShouldLoad] = useState(false)

    const getLocations = () => {
        axios.get("/api/admin/locations/all")
            .then((data) => {
                setLocations(data.data)
                // console.log("location data", data.data)
                // setShouldLoad(true);
            })
            .catch((err) => {
                console.log("error", err);
                toast.error(err)
            });
    }


    const getGroupLocation = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then(data => {
                // console.log("Group Locations", data.data)
                setGroupLocations(data.data)
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect((e) => {
        getGroupLocation();
        getLocations();
    }, [])
    
    ////////////////////////////////////////////////   
    ///////  State Hooks per hapjen dhe /////////// 
    ////////  manipulimit modalit ////////////////// 
    //////////////////////////////////////////////// 
    const [isdeleteGroupOpen , setIsDeleteGroupOpen] = useState(false)
    const [isEditGroupOpen , setIsEditGroupOpen] = useState(false);
    const [isAddGroupOpen, setAddGroupOpen] = useState(false);
    const [isAddGroupLocationOpen, setAddGroupLocationOpen] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);

    ////////////////////////////////////////////////   
    ////////  Kontrollimi i hapjes te modalit //////
    ///////////////////////////////////////////////    

    const renderViewModal = (data) => {
        // console.log('Arton Redner View modal', data);
        setIsViewOpen(true);
        setDataToModal(data);
    }
    const renderEditModal = (data) => {
        // console.log('edit data', data);
        setIsEditModal(true);
        setDataToModal(data);
    }
    const renderDeleteModal = (data) => {
        // console.log('remderFnData', data);
        setIsDeleteOpen(true);
        setDataToModal(data);
    }
    const renderEditGroupModal = (data) => {
        // console.log("Edit Group data" , data)
        setIsEditGroupOpen(true);
        setDataToModal(data);
    }
    const renderDeleteGroupModal = (data) => {
        setIsDeleteGroupOpen(true);
        setDataToModal(data)
    }

    const renderAddServiceOrLocationModal = (data) => {
        // console.log('renderAddServiceOrLocationModal', data);
        setAddGroupLocationOpen(true);
        setDataToModal(data);
    }
    return (
        <>
            <AddLocation props={dataToModal} isOpen={isAddGroupLocationOpen} handleClose={() => setAddGroupLocationOpen(false)} />
            <AddGroupLocationModal props={dataToModal} isOpen={isAddGroupOpen} handleClose={() => setAddGroupOpen(false)} />
            <ViewLocation props={dataToModal} isOpen={isViewOpen} handleClose={() => setIsViewOpen(false)} />
            <DeleteLocation props={dataToModal} isOpen={isDeleteOpen} handleClose={() => setIsDeleteOpen(false)} />
            <EditLocation props={dataToModal} isOpen={isEditModal} handleClose={() => setIsEditModal(false)} />
            <EditGroupLocationModal props={dataToModal} isOpen={isEditGroupOpen} handleClose={() => setIsEditGroupOpen(false)} />
            <DeleteGroupLocation props={dataToModal} isOpen={isdeleteGroupOpen} handleClose={() =>setIsDeleteGroupOpen(false)} />

            <header className="mainpage-header">
                <nav className="navbar pb-4 ps-4 main-items">
                    <div className="container">
                        <h5 className="text-light pt-3 main-title">SERVICES-*MAIN* ADMINISTRATOR PORTAL</h5>
                        <form className=" d-flex gap-5 ">
                            <a className="btn btn-sm-5 btn-secondary main-buttons" onClick={(e) => { e.preventDefault(); setAddGroupOpen(true) }}>Add Group < i className="fa ms-1 fa-circle-plus" /></a>
                            <a className="btn btn-sm-5 btn-secondary main-buttons" onClick={(e) => { e.preventDefault(); renderAddServiceOrLocationModal(true) }}>Add Service/Location < i className="fa fa-circle-plus" /></a>
                        </form>
                    </div>
                </nav>
            </header>


            <div className="tab listcon mt-2">
                <button onClick={() => setShowTab(true)}>LOCATIONS</button>
                <button onClick={() => setShowTab(false)}>GROUP LOCATIONS</button>
            </div>

            {showTab === true ?
                <div >
                    <div className="Test">

                        <h5 className=" container  mx-auto pt-5">SERVICES LOCATION MANAGEMENT MODULE</h5>

                        {locations === null ? <h1 className="mt-5">No Locations Found</h1>
                            :
                            <>
                                <div className="row rounded mt-5 border pb-1 pt-1 lista">
                                    <div className="col-sm-1">ID</div>
                                    <div className="col-sm-1">view <i className="fa fa-eye" /></div>
                                    <div className="col-sm-1">edit <i className="fa fa-edit" /></div>
                                    <div className="col-sm-1">delete <i className="fa fa-trash" /></div>
                                    <div className="col-sm-2">Name AL</div>
                                    <div className="col-sm-2">Name EN</div>
                                    <div className="col-sm-2">Name SR</div>
                                    <div className="col-sm-2">Name TR</div>

                                </div>
                                {locations?.map((el) => {
                                    // console.log("elementet", el);

                                    return (
                                        <>
                                            <div className="row rounded mt-3 border pt-2 pb-2 mb-3 listcon">
                                                <div className="col-sm-1">{el.id}</div>
                                                <div className="col-sm-1 hoverViewEdit" onClick={(e) => { e.preventDefault(); renderViewModal(el) }}>view <i className="fa fa-eye" /></div>
                                                <div className="col-sm-1 hoverViewEdit" onClick={(e) => { e.preventDefault(); renderEditModal(el) }}>edit <i className="fa fa-edit" /></div>
                                                <div className="col-sm-1 delete-location" onClick={(e) => { e.preventDefault(); renderDeleteModal(el.id) }} role="button">delete <i className="fa fa-trash" /></div>
                                                <div className="col-sm-2">{el.name_al}</div>
                                                <div className="col-sm-2">{el.name_en}</div>
                                                <div className="col-sm-2">{el.name_sr}</div>
                                                <div className="col-sm-2">{el.name_tr}</div>

                                            </div>
                                        </>
                                    );
                                })}

                            </>
                        }
                    </div>
                </div>
                :
                <div >
                    <div className="wrapper">
                        <h5 className="mx-auto pt-5">GROUP LOCATION MANAGEMENT MODULE</h5>
                        {groupLocations === null ? <h1 className="mt-5">No Group Locations Found</h1> :
                            <>
                                <div className="row rounded mt-5 mb-3 border pb-1 pt-1 lista">
                                    <div className="col-sm-1">ID</div>
                                    <div className="col-sm-1">view <i className="fa-solid fa-eye"></i></div>
                                    <div className="col-sm-1">edit <i role="button" className="fa-solid fa-pen-to-square" /></div>
                                    <div className="col-sm-1">delete <i className="fa fa-trash"></i></div>
                                    <div className="col-sm-2">Name Al</div>
                                    <div className="col-sm-2">Name EN</div>
                                    <div className="col-sm-2">Name SR</div>
                                    <div className="col-sm-2">Name TR</div>
                                </div>
                                {groupLocations?.map(el => {
                                    // console.log("first " , el)
                                    return (
                                        <>
                                            <div className="row rounded mt-3 border pt-2 pb-2 mb-3 listcon">
                                                <div className="col-sm-1">{el.id}</div>
                                                <div className="col-sm-1 hoverViewEdit" role="button" onClick={(e) => {}}>view <i className="fa-solid fa-eye" /></div>
                                                <div className="col-sm-1 hoverViewEdit" role="button" onClick={(e) => { e.preventDefault(); renderEditGroupModal(el) }}>edit <i role="button" className="fa-solid fa-pen-to-square" /></div>
                                                <div className="col-sm-1 delete-location" role="button" onClick={(e) => {e.preventDefault(); renderDeleteGroupModal(el.id)}}>delete <i className="fa fa-trash"></i></div>
                                                <div className="col-sm-2">{el.name_al}</div>
                                                <div className="col-sm-2">{el.name_en}</div>
                                                <div className="col-sm-2">{el.name_sr}</div>
                                                <div className="col-sm-2">{el.name_tr}</div>
                                            </div>
                                        </>
                                    );
                                })}
                            </>
                        }
                        <button className="btn btn-secondary mt-5 adduser-btn add-new-question-btn" onClick={() => render(<AddGroupLocationModal getGroupLocation={getGroupLocation} />)}><h4>Add Group</h4></button>
                    </div>
                </div>}
        </>
    );
}
export default Services;