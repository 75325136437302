import React from "react";
import { useState, useEffect } from "react";
import "./widgetModal.scss"
import axios from '../../axios';
import { toast } from "react-toastify";
import sign from "jwt-encode";
import ReactPortal from "../portals/ReactPortal";
function Widget({ getWidget, isOpen, handleClose }) {

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);
    // const Widget = (props) => {
    const secret = process.env.REACT_APP_WIDGET_SECRET;
    // console.log('widgetsec', secret)
    const [widgetmodal, setWidgetmodal] = useState(true)
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState(null);
    const [typeID, setTypeID] = useState();
    const [locationGroups, setLocationGroups] = useState();
    const [selectedLanguage , setSelectedLanguage]= useState('sq')

    const handlelocationID = (e) => {
        e.preventDefault()
        setLocationId(e.target.value)
    }
    const handleSelectedLang = (e) => {
        e.preventDefault()
        setSelectedLanguage(e.target.value)
    }
    const handleTypeID = (e) => {
        e.preventDefault()
        setTypeID(e.target.value)
    }
    const languagesToMap = [
        {
            text: "Shqip",
            value: 'al',
        },
        {
            text: "English",
            value: 'en',
        },
        {
            text: "Serbian",
            value: 'sr',
        }
    ]
    const TypesOfWidgets = [
        {
            text: "Local",
            value: 1,
        },
        {
            text: "Global",
            value: 2,
        }
        //   {
        //     text: "tipi 3 ma i forti",
        //     value: 3,
        //   },
    ]
    // console.log('typesOf', TypesOfWidgets)
    const getLocationGroups = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then((data) => {
                setLocationGroups(data.data);
                // console.log('arton', data.data)
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    const getLocations = () => {
        axios.get("/api/admin/locations/all")
            .then((data) => {
                setLocations(data.data);
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    useEffect(() => {
        getLocations();
        getLocationGroups();
    }, [])

    const addWidget = (e) => {
        e.preventDefault()
        let widgetdata = {
            location_id: locationId,
            type: e?.target?.type?.value,
            content: e?.target?.content.value
        }
        axios.post("/api/admin/widgets", widgetdata)
            .then(data => {
                // console.log(`widgetadd`, data.data)
                // setWidgetmodal(false)
                handleClose();
                getWidget()
                toast.success("Widget added Sucesfully")
            }).catch(err => {
                console.log('error', err)
            })
    }

    // console.log('location_id', locationId)
    let locationIdEncrypt = sign(locationId, secret);
    let typeEncrypt = sign(typeID, secret);
  
    // console.log('typeEncrypt', locationGroups)


    const iframeStart = `<iframe class="chemonics-widget" src="https://widget.e-gjykata.com/${selectedLanguage}/${typeEncrypt}/${locationIdEncrypt}" `;
    // const widgetData = locationIdEncrypt;
    const iframeEnd = ` frameborder="0" style="width:100%;height: 100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px;"></iframe>`;

    const handleWidgetModal = () => {
        setWidgetmodal(false)
    }
    if (isOpen) {
        return (
            <ReactPortal wrapperId="WidgetModal">
                <div className="wrapper">
                    <div className="widget-modal fade-in">
                        <div className="handleClose" onClick={() => handleClose()}></div>
                        <div className="widget-content">
                            <div className="widget-header">
                                <h5 className="mx-auto pt-3 pb-3">ADD NEW WIDGET</h5><h5 role="button" className="pt-3 mx-atuo iksiHeader" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                            </div>
                            <form onSubmit={addWidget}>
                                <div className="widget-body ms-2">
                                    <h4 className="d-flex pb-2 left ps-4">Type</h4>
                                    <div className=" ps-4 col-sm-11 mb-4">
                                        <select required onChange={handleTypeID} id="type" name="type" className="form-select">
                                            <option value="" selected hidden>Choose the type of widget</option>
                                            {TypesOfWidgets.map(el => {
                                                // console.log('elapo', el)
                                                return (
                                                    <option value={el.value}>{el.text}</option>
                                                );
                                            })
                                            }
                                        </select>
                                    </div>
                                    <h4 className="d-flex pb-2 left ps-4">Create a widget by location</h4>
                                    <div className=" ps-4 col-sm-11 mb-4">
                                        <select required onChange={handlelocationID} className="form-select">
                                            <option value="" selected hidden>Choose location</option>
                                            {typeID === "1" ?
                                                locations?.map(el => {
                                                    return (
                                                        <option value={el.id}>{el.name_al}</option>
                                                    )
                                                })
                                                :
                                                typeID === "2" ?
                                                    locationGroups?.map(el => {
                                                        return (<option value={el.id}>{el.name_al}</option>
                                                        )
                                                    })
                                                    :
                                                    <option disabled selected >Please select the type of Widget</option>
                                            }
                                        </select>
                                    </div>



                                    {/* /////////////////////////////////////////////// */}
                                    <h4 className="d-flex pb-2 left ps-4">Select a language for this widget</h4>
                                    <div className=" ps-4 col-sm-11 mb-4">
                                        <select required onChange={handleSelectedLang} className="form-select">
                                            <option value="" selected hidden>Choose language</option>


                                            {languagesToMap?.map(el => {
                                                return (<option value={el.value}>{el.text}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    {/* /////////////////////////////////////////////// */}


                                    <h4 className="d-flex pb-2 left ps-4">Content</h4>
                                    <div className="ps-4 col-sm-11 mb-4">

                                        <textarea type={'text'} value={iframeStart + iframeEnd} disabled id="content" name="content" cols="56" rows="5"></textarea>
                                    </div>
                                </div>
                                <button className="btn p-5 pt-3 pb-2 mt-3 widget-btn" type="submit"><h4>SAVE WIDGET</h4></button>
                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        );
    } else return null;
}
export default Widget;