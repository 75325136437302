import React, { useEffect, useState } from "react";
import "./viewLocation.scss";
import axios from '../../../axios';
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";

// const ViewLocation = (props) => {
function ViewLocation({ props, isOpen, handleClose }) {
// console.log('viewProps',props)

    //////////// Control Esc per mbyllje te modalit
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);
    
    const [locationdata, setLocationData] = useState(null);
    const [shouldSave, setShouldSave] = useState(false)
    const [serviceModal, setServiceModal] = useState(true)

    const getLocationData = () => {
        axios.get(`/api/admin/locations/${props?.id}`)
            .then((res) => {
                setLocationData(res?.data)
                // console.log('locationResponse', res)
            })
            .catch((err) => {
                console.log("error", err);
                toast.error(err);
            });
    }


    useEffect(() => {
        getLocationData();
    }, [isOpen])

    if (isOpen === true) {
        return (
            <ReactPortal wrapperId="ViewLocationModal">
                    <div className="edit-service-modal fade-in" >
                <div className="wrapper handleClose" onClick={()=>handleClose()}> </div>
                        <div className="edit-service-content ">
                            <div className="edit-service-header ">
                                <h5 className="mx-auto pt-3 pb-3  ">ADD NEW Service</h5>
                                <h5 className="iksi pt-3 pb-3 " onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                            </div>
                            <h5 className="d-flex left ps-4 ms-2">Location Name AL</h5>
                            <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                <input disabled type="text" className="form-control form-control-lg" name="text" id="name_al" defaultValue={locationdata?.name_al} placeholder="e.g Basic Court Prishtina" />
                            </div>
                            <h5 className="d-flex left ps-4 ms-2">Location Name EN</h5>
                            <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                <input disabled type="text" className="form-control form-control-lg" name="text" id="name_en" defaultValue={locationdata?.name_en} placeholder="e.g Basic Court Prishtina" />
                            </div>
                            <h5 className="d-flex left ps-4 ms-2">Location Name SR</h5>
                            <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                <input disabled type="text" className="form-control form-control-lg" name="text" id="name_sr" defaultValue={locationdata?.name_sr} placeholder="e.g Basic Court Prishtina" />
                            </div>
                            <h5 className="d-flex left ps-4 ms-2">Location Name TR</h5>
                            <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                <input disabled type="text" className="form-control form-control-lg" name="text" id="name_sr" defaultValue={locationdata?.name_tr} placeholder="e.g Basic Court Prishtina" />
                            </div>
                        </div>
                    </div>
            </ReactPortal>
        );
    } else return null;
}
export default ViewLocation;