import React from 'react'
import "./surveyAnswers.scss"
import axios from '../../axios'
import { useState } from 'react'
import { useEffect } from 'react'
import { toast } from "react-toastify";
import * as FileSaver from "file-saver"
import XLSX from "sheetjs-style"

const SurveyAnswers = () => {

  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  
  const exportToExcel = () =>{
    const ws = XLSX.utils.json_to_sheet(surveyAnswers);
    const wb = { Sheets: { "data" : ws} , SheetNames: [`data`] };
    const excelBuffer = XLSX.write(wb , {BookType : `xlsx` , type:`array`} );
    const data = new Blob([excelBuffer] , {type:fileType});
    FileSaver.saveAs(data, `Survey Answers` + fileExtension);
  }
  
  const locationID = window.localStorage.getItem("location_id")
  
  const [surveyAnswers, setSurveyAnswers] = useState([])
  const [locations, setLocations] = useState([])
  const [getLocationId, setGetLocationId] = useState(locationID);
  const [getFromDate, setFromDate] = useState('2022-01-01');
  const [getToDate, setToDate] = useState('2022-12-31');

  
  
    
  const handleFromDate = (e) => {
    e.preventDefault();
    setFromDate(e.target.value)
  }
  // console.log("From Date", getFromDate, "To Date", getToDate, "LocationId", getLocationId)
  const handleToDate = (e) => {
    e.preventDefault();
    setToDate(e.target.value)
  }
  const handleLocationId = (e) => {
    e.preventDefault();
    setGetLocationId(e.target.value)
  }
  const getSurveyAnswers = (e) => {
    e.preventDefault();

    // axios.get(`/api/admin/survey-answers/listing/9999/2022-01-01/2022-12-31`)
    axios.get(`/api/admin/survey-answers/listing/${getLocationId}/${getFromDate}/${getToDate}`)
      .then(data => {
        setSurveyAnswers(data.data)
        // console.log("survey answers mir se vini", data)
      }).catch(err => {
        console.log(err)
      })
  }

  const getLocations = () => {
    axios.get(`/api/admin/locations/filter-by-location/${locationID}`)
      .then((data) => {
        setLocations(data.data)
        // console.log("location data", data.data)
        // setShouldLoad(true);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(err)
      });
  }

  useEffect(() => {
    // getSurveyAnswers()
    getLocations();
  }, [])



  return (
    <>
      <header className="surveys-header">
        <nav className="navbar main-items">
          <div className="kontentiHeared container d-flex">
            <h5 className="text-light main-title">SURVEY ANSWERS-*MAIN* ADMINISTRATOR PORTAL</h5>
          </div>
        </nav>
      </header>
      <div className="container mt-4">
        <div className="row">
          <div className="col-sm d-flex">
            <h4>From date</h4>
          </div>
          <div className="col-sm-8 d-flex">
            <h4>To date</h4>
          </div>
        </div>
      </div>

      <form >
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <input type="date" className="form-control" onChange={handleFromDate} />
            </div>
            <div className="col-sm">
              <input type="date" className="form-control" onChange={handleToDate} />
            </div>
     
                <div className="col-sm d-flex">
                  <select className='form-select' onChange={handleLocationId}>
                    <option value="">-- Select Location --</option>
                    {locations?.map(el => {
                      return (
                        <>
                          <option value={el.id}>{el.name_al}</option>
                        </>
                      )
                    })}
                  </select>
            <button type='submit' className='btn btn-success ms-4' onClick={getSurveyAnswers}>Search</button>
                </div> 
          </div>
        </div>
      </form>
      <div className="d-flex flex-row-reverse container mt-5">
        <button className='btn btn-success' onClick={()=> exportToExcel()}>Export Excel <i class="fa-solid fa-file-excel ms-2" /></button>
      </div>
      <div className="row rounded border pb-2 pt-2 sanswerslist mx-auto container mt-3">
        <div className="col-sm-1">ID</div>
        <div className="col-sm-3">Question AL</div>
        <div className="col-sm-4">Note</div>
        <div className="col-sm-3">Option</div>
        <div className="col-sm-1">Rating</div>
      </div>
      {surveyAnswers < [0] ?
        "Fill The Information" :
        <>
          {surveyAnswers?.map(el => {
            return (
              <>
                <div className="row rounded border pb-2 pt-2 mx-auto container mt-3">
                  <div className="col-sm-1">{el?.id}</div>
                  <div className="col-sm-3">{el?.question_al}</div>
                  <div className="col-sm-4">
                    {el?.note === "" ?
                      "No notes written"
                      :
                      <>
                        {el?.note}
                      </>
                    }
                  </div>
                  <div className="col-sm-3">
                    {el?.survey_answer_option === null || "" ?
                      "No Option Answered"
                      :
                      <>
                        {el?.option_al}
                      </>
                    }
                  </div>
                  <div className="col-sm-1">
                    {el.answer}
                  </div>
                </div>
              </>
            )
          })}
        </>
      }
    </>
  )
    }
export default SurveyAnswers;