import React from "react"
import "./Signin.scss";
import axios from "../../axios";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";


const SignInPage = () => {
    const [captchatoken, setCaptchaToken] = useState(1)
    const [captchatoken1, setCaptchaToken1] = useState("value catcher")
    let onChange = (value) => {
        setCaptchaToken(value)
        setCaptchaToken1(value)
    }
    const submitLogin = async (e) => {
        e.preventDefault();
        let credentials = {
            email: e?.target.email.value,
            pass: e?.target.password.value,
        };

        axios
            .post("/api/auth/login", credentials)
            .then((res) => {
                window.localStorage.setItem("token", res?.data?.token);
                const loginData = jwt_decode(res?.data?.token)
                window.localStorage.setItem("userID", loginData?.id);
                window.localStorage.setItem("roleId", loginData?.role);
                window.localStorage.setItem("location_id", loginData?.location_id);
                window.localStorage.setItem("logged_in", true);
                window.location.reload()
                toast.success('Logged In!');

            })
            .catch((err) => {
                console.log("error", err);
                toast.error("Log in credentials incorrect ");
            });
    }


    return (
        <>
            <div className="wrapper">
                <div className="signin-header">
                    <h5 className="ms-4 pt-4 pb-3 ps-4 mt-1">SIGN IN PAGE</h5>
                </div>
                <form onSubmit={submitLogin}>
                    <center>
                        <div className="ps-4 col-sm-5 mb-4">
                            <h4 className="d-flex left">User Name</h4>
                            <input type="email" name="email" id="email" className="form-control form-control-lg" placeholder="e.g. admin@chemonics.com" />
                        </div>

                        <div className=" ps-4 col-sm-5 mb-4">
                            <h4 className="d-flex left">Password</h4>
                            <input type="password" name="password" id="password" className="form-control form-control-lg" placeholder="************" />
                        </div>

                        <ReCAPTCHA
                            theme={"light"}
                            sitekey={process.env.REACT_APP_SITE_KEY}
                            onChange={onChange}
                        />

                        {captchatoken === captchatoken1 ?
                            <button type="submit" className="btn p-5 pt-2 pb-2 mt-3 signin-btn mb-5">Sign in</button>
                            :
                            <button disabled type="submit" className="btn p-5 pt-2 pb-2 mt-3 signin-btn mb-5">Sign in</button>
                        }
                    </center>
                </form>
            </div>

        </>
    );
}
export default SignInPage;