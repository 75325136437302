import React from "react";
import "./ChangePassword.scss"
import ReactPortal from "../../portals/ReactPortal";
import axios  from "../../../axios";
import { useEffect } from "react";
import {toast} from "react-toastify"
import { useState } from "react";


function ChangePasswordModal({ dataToModal, isOpen, handleClose }) {
    const user_id = window.localStorage.getItem("userID");

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    const UpdatePassword = (e) => {
        e.preventDefault();
            let oldPass = e.target.oldPass.value;
            let newPass = e.target.newPass.value;
            let confPass = e.target.confPass.value

            if (oldPass === "" || oldPass === null || newPass === "" || confPass === "" || newPass === null || confPass === null) {
                toast.error("Please fill the correct information");
            }
            else if (oldPass !== "" && newPass !== "" && confPass !=="") {
                if(confPass === newPass){
                const passData = {
                    oldPass: oldPass,
                    pass: newPass,
                } 
                console.log("Pass DATA",passData);
                axios.put(`/api/admin/users/editPassword/${user_id}`, passData)
                    .then(data => {
                        if (data.data === "Please enter correct old password") {
                            toast.error(data.data);
                        }
                        else if (data.data.updated == true) {
                            console.log("password Changed", data)
                            toast.success("Password changed Sucesfully!")
                        }
                    }).catch(err => {
                        console.log('.err', err)
                        toast.error("Something went wrong")
                    })
                }else toast.error("New password is not the same")
            }
    
        }
        
        if (!isOpen) return null; 
        return (
            <ReactPortal wrapperId="ChangePassword">
                    <div className="passwordChange-modal">
                    <div onClick={()=>handleClose()} className="handleClose"></div>
                        <div className="passwordChange-content">
                            <div className="passwordChange-header d-flex">
                                <h3 className="pb-1 pt-1 ms-3 mx-auto">Change Your Password</h3> <h3 className="x-btn pt-1 " 
                                onClick={()=>handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "25px" }} /></h3>
                            </div>
                            <form onSubmit={UpdatePassword}>
                                <div className="row mx-auto gap-3 col-sm-6 mt-3">
                                <input type="password" id="oldPass" placeholder="Enter Old Password" className="form-control"/>
                                <input type="password" id="newPass" placeholder="Enter New Password" className="form-control"/>
                                <input type="password" id="confPass" placeholder="Confirm Password" className="form-control"/>
                                </div>
                            <button type="submit" className="btn btn-success mx-auto mb-3 mt-3  d-flex">Change Password</button>
                    </form>
                            </div>
                    </div>
            </ReactPortal>
        )
   
}
export default ChangePasswordModal;