// import React from "react";
import { useState, useEffect} from "react";
import "./ViewWidget.scss"
import ReactPortal from "../../portals/ReactPortal";

function ViewWidget({ data, getWidget, isOpen, handleClose }) {

    useEffect(() => {
      const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
      document.body.addEventListener("keydown", closeOnEscapeKey);
      return () => {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
      };
    }, [handleClose]);

// const ViewWidget = (data) => {
    // const [widgetmodal, setWidgetmodal] = useState(true)
// console.log('widgetdata', data)

// const handleWidgetModal = () => {
//     setWidgetmodal(false);
// }
    if (isOpen === true) {
        return (
            <ReactPortal wrapperId="ViewWidget">
            <div className="wrapper">
                    <div className="widget-modal fade-in">
                        <div className="handleClose" onClick={()=>handleClose()}></div>
                        <div className="widget-content">
                            <div className="widget-header">
                                <h5 className="mx-auto pt-3 pb-3">WIDGET</h5><h5 className="pt-3 iksiHeader" role="button" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{color :"black" , fontSize:"larger"}}/></h5>
                            </div>
                            <form>
                                <div className="widget-body ms-2">
                                <h4 className="d-flex pb-2 left ps-4">Type</h4>
                                    <div className=" ps-4 col-sm-11 mb-4">
                                        <select disabled id="type" name="type" className="form-select">
                                            <option>{data?.type}</option>
                                        </select>
                                    </div>
                                    <h4 className="d-flex pb-4 left ps-4">widget by location</h4>
                                    <div className=" ps-4 col-sm-11 mb-4">
                                        <select disabled className="form-select">
                                            <option>{data?.location_id}</option>  
                                        </select>
                                    </div>
                                    <h4 className="d-flex pb-2 left ps-4">Content</h4>
                                    <div className="ps-4 col-sm-11 mb-4">
                                        <textarea disabled defaultValue={data?.content} id="content" name="content" cols="56" rows="5"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        );
    } else return null;
}
export default ViewWidget;