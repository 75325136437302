import React from "react";
import "./Surveys.scss"
import { render } from "@testing-library/react";
// import AddService from "../servicesLocations/AddService"
import { useState, useEffect } from "react";
import Select from 'react-select';
import axios from '../../axios';
import { Link, Navigate } from "react-router-dom";
import DeleteSurvey from './deleteSurvey'
import ViewSurvey from './viewSurvey/viewSurvey'
import EditSurvey from './editSurvey/editSurvey'



const Surveys = (props) => {
    const [selectedOption, setSelectedOption] = useState();
    const [surveys, setSurveys] = useState([]);
    const [locations, setLocations] = useState([]);
    const [locationid, setLocationid] = useState();



    const handlelocationId = (e) => {
        e.preventDefault();
        window.location.href = `/addSurvey/${(e?.target?.value).replace(",", "/")}`
    }
    const location_id = window.localStorage.getItem('location_id');

    const getSurveys = () => {
        axios.get(`/api/admin/surveys/filter-by-location/${location_id}`)
            .then(data => {
                // console.log("surveys", data.data)
                setSurveys(data.data)
            }).catch(err => {
                console.log(err)
            })
    }

    const getLocations = () => {
        axios.get(`/api/admin/locations/filter-by-location/${location_id}`)
            .then(data => {
                // console.log("locations", data.data)
                setLocations(data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    // const lokacioni =  locations?.find((el) => el.id === el.id).name_al
    // ?.content
        ////////////////////////////////////////////////   
      ///////  State Hooks per hapjen dhe /////////// 
     ////////  manipulimit modalit ////////////////// 
    //////////////////////////////////////////////// 

    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [data , setData] = useState([])


     ////////////////////////////////////////////////   
////////  Kontrollimi i hapjes te modalit //////
///////////////////////////////////////////////    
const renderEditModal = (data) => {
    // console.log('remderFnData', data);
    setIsEditOpen(true);
    setDataToModal(data);
}
const renderDeleteModal = (data) => {
    // console.log('remderFnData', data);
    setIsDeleteOpen(true);
    setDataToModal(data);
}
const renderViewModal = (data) => {
    // console.log('remderFnData', data);
    setIsViewOpen(true);
    setDataToModal(data);
}

    useEffect(() => {
        getSurveys();
        getLocations();

    }, [])


    const qomalokacionin = <Link to="/addSurvey" />
    return (
        <>
        <DeleteSurvey data={dataToModal} getSurveys={()=>getSurveys()} handleClose={()=>setIsDeleteOpen(false)} isOpen={ dataToModal && isDeleteOpen} />
        <EditSurvey props={dataToModal} handleClose={()=>setIsEditOpen(false)} isOpen={ dataToModal && isEditOpen} />
        <ViewSurvey  props={dataToModal} isOpen={isViewOpen} handleClose={()=>setIsViewOpen(false)}/>
            <div className="wrapper">
                <header className="surveys-header">
                    <nav className="navbar main-items">
                        <div className="kontentiHeared container d-flex">
                            <h5 className="text-light main-title">SURVEYS-*MAIN* ADMINISTRATOR PORTAL</h5>
                            {/* <Link className="btn btn-sm-5 btn-secondary main-buttons" locationid={handleGetLocationId} to={"/AddSurvey"}>Add Survey <i className="fa-regular fa-plus ps-2"></i></Link> */}
                            {location_id == 9999 ?
                                <div className="col-sm-3">
                                    <select className="form-control" defaultValue="emptySelection" onChange={handlelocationId}>

                                        <option value="emptySelection" disabled>Add Survey To Location</option>
                                        {locations.map(el => {
                                            return (
                                                <>
                                                    <option value={[el.id, el.group_id]}>{el.name_al}</option>
                                                </>
                                            )
                                        })}
                                    </select>
                                </div>
                                :
                                ""
                            }

                        </div>
                    </nav>
                </header>
                <center>
                    <h5 className="pt-5">SURVEYS LOCATION MANAGEMENT MODULE</h5>
                </center>
                {surveys === null ? <h1 className="mt-5">No Surveys Found For This Location</h1>
                    :
                    <>
                        <div className="row rounded mt-5 border pb-2 pt-2 lista">
                            <div className="col-sm-1">ID</div>
                            <div className="col-sm-1">View <i className="fa fa-eye" /></div>
                            <div className="col-sm-1">Edit <i className="fa fa-pen-to-square" /></div>
                            {location_id == 9999 ? <div className="col-sm-1">Delete <i className="fa fa-trash" /></div> : ""}
                            <div className="col-sm-4">SURVEY NAME</div>
                            <div className="col">Service/Location</div>
                        </div>

                        {surveys?.map(map => {
                            return (
                                <div className="row rounded mt-3 border pb-3 pt-3 listcon surveyListing">
                                    <div className="col-sm-1">{map.id}</div>
                                    <div className="col-sm-1 hoverView"onClick={() => renderViewModal(map)} >View <i className="fa fa-eye" /></div>
                                    <div className="col-sm-1 hoverView" onClick={(e) => {e.preventDefault(); renderEditModal(map)}}>Edit <i className="fa fa-pen-to-square" /></div>
                                    {location_id == 9999 ?
                                        <div className="col-sm-1 hoverDelete" onClick={() => renderDeleteModal(map)}>Delete <i className="fa fa-trash" /></div> : ""}
                                    <div className="col-sm-4">{map.name_al}</div>
                                    <div className="col">{locations?.find((el) => el?.id === map?.location_id)?.name_al}</div>
                                </div>
                            );
                        })}

                    </>}
            </div>
        </>
    );
}

export default Surveys;