import React, { useEffect, useState } from "react";
import "./editLocation.scss";
import axios from '../../../axios';
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";

// const EditLocation = (props) => {
function EditLocation({ props, isOpen, handleClose }) {
    // console.log('editProps', props)

    //////////// Control Esc per mbyllje te modalit
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);
    const [locationdata, setLocationData] = useState(null);
    const [shouldSave, setShouldSave] = useState(false)
    const [serviceModal, setServiceModal] = useState(true)
    const [groupLocationId, setgroupLocationId] = useState();
    const [groupLocations, setGroupLocations] = useState([]);

    const handleGroupLocationid = (e) => {
        e.preventDefault();
        setgroupLocationId(e.target.value)
    }

    const submitLocation = (e) => {
        e.preventDefault();
        setShouldSave(true)
        const locationDataChanged = {
            id: props?.id,
            name_al: e?.target.name_al.value,
            name_en: e?.target.name_en.value,
            name_sr: e?.target.name_sr.value,
            name_tr: e?.target.name_tr.value,
            group_id: groupLocationId
        };
        // console.log('qitoqato', locationDataChanged, locationdata )
        if (
            locationDataChanged?.name_al !== locationdata.name_al ||
            locationDataChanged?.name_en !== locationdata.name_en ||
            locationDataChanged?.name_sr !== locationdata.name_sr ||
            locationDataChanged?.group_id !== locationdata.group_id

        ) {
            axios.put(`/api/admin/locations`, locationDataChanged)
                .then((res) => {
                    // console.log('locationResponse', res.data)
                    setServiceModal(false);
                    toast.success('Sucessfully edited location!');

                })
                .catch((err) => {
                    console.log("error", err);
                    toast.error('There was an error on editing the location!');
                });
        } else {
            toast.error('No changed made!')
        }
    }
    const getGroupLocationData = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then(data => {
                // console.log("group locations" , data.data)
                setGroupLocations(data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    const getLocationData = () => {
        axios.get(`/api/admin/locations/${props?.id}`)
            .then((res) => {
                setLocationData(res?.data)
                // console.log('locationResponse', res)
            })
            .catch((err) => {
                console.log("error", err);
                toast.error(err);
            });
    }


    useEffect(() => {
        getGroupLocationData()
        getLocationData();
    }, [isOpen])
    const defaultgroupname = groupLocations?.find((el) => el.id === locationdata?.group_id)?.name_al;
    // console.log('defaultgroupname',defaultgroupname)

    if (isOpen === true) {
        return (
            <ReactPortal>
                    <div className="edit-service-modal fade-in" >
                <div className="wrapper handleClose " onClick={()=> handleClose()}>
                </div>
                        <div className="edit-service-content">
                            <form onSubmit={submitLocation}>
                                <div className="edit-service-header ">
                                    <h5 className="mx-auto pt-3 pb-3  ">EDIT NEW Service</h5>
                                    <h5 className="iksi pt-3 pb-3 " onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name AL</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_al" defaultValue={locationdata?.name_al} placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name EN</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_en" defaultValue={locationdata?.name_en} placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name SR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_sr" defaultValue={locationdata?.name_sr} placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name TR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_tr" defaultValue={locationdata?.name_tr} placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Parent Of :</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <select onChange={handleGroupLocationid} className="form-control form-control-lg">
                                        <option hidden value={locationdata?.group_id}>{defaultgroupname}</option>
                                        {groupLocations.map(el => {
                                            return (
                                                <option value={el.id}>{el.name_al}</option>
                                            );
                                        })}
                                    </select>
                                    {/* <input type="text" className="form-control form-control-lg" name="text" id="name_sr"  defaultValue={locationdata?.name_sr} placeholder="e.g Basic Court Prishtina" /> */}
                                </div>
                                <button type="submit" className="btn Service-btn mb-5" >SAVE/UPDATE</button>
                            </form>
                        </div>
                    </div>
            </ReactPortal>
        );
    } else return null;
}
export default EditLocation;