import React from "react";
import "./AddSurvey.scss"
import axios from '../../../axios';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom"

const AddSurvey = (props) => {
    // console.log('addsurveyProps', props)
    const { id } = useParams();
    // console.log("id",id)
    // console.log('props', props)
    const [option, setOption] = useState([]);

    //              SET QUESTIONER NAME                    //
    let grupiSurveys = window.location.pathname.split("/").pop();
    // console.log('grup``i lokacionit',grupiSurveys);
    const emriPytesoritObj = useState({ name_al: "", name_en: "", name_sr: "", name_tr: "", location_id: id, isDeleted: 0, group_id: grupiSurveys})
    const [surveyName, setSurveyName] = useState([]);
    const handleSurvey = (e, lang) => {
        e.preventDefault();
        let currentState = emriPytesoritObj;
        if (lang === 'al') {
            let assignal = Object.assign(currentState);
            assignal[0].name_al = e.target.value;
        }
        if (lang === 'en') {
            let assignen = Object.assign(currentState);
            assignen[0].name_en = e.target.value;
        }
        if (lang === 'sr') {
            let assignsr = Object.assign(currentState);
            assignsr[0].name_sr = e.target.value;
        }
        if (lang === 'tr') {
            let assigntr = Object.assign(currentState);
            assigntr[0].name_tr = e.target.value;
        }
    };

    //            --  SET QUESTIONER NAME  --              //
    //                   SET QUESTIONS                     //
    const [initialState, setInitialState] = useState([]);
    const setQuestions = () => {
        setInitialState(oldstate => [...oldstate, { question_al: "", question_en: "", question_sr: "", question_tr: "", options: [] }]);
        setSurveyName(emriPytesoritObj);
        // console.log("op",initialState?.options)

    }
    const handleQuestion = (e, index, lang) => {
        e.preventDefault();
        // console.log('index123', e)
        let currentState = initialState;
        if (lang === 'al') {
            let assignal = Object.assign({}, currentState);
            assignal[index].question_al = e.target.value;
        }
        if (lang === 'en') {
            let assignen = Object.assign({}, currentState);
            assignen[index].question_en = e.target.value;
        }
        if (lang === 'sr') {
            let assignsr = Object.assign({}, currentState);
            assignsr[index].question_sr = e.target.value;
        }
        if (lang === 'tr') {
            let assigntr = Object.assign({}, currentState);
            assigntr[index].question_tr = e.target.value;
        }
    };

    //                --  SET QUESTIONS  --                //

    //                  SET QUESTION OPTIONS               //
    const setOptions = (e, pindex, data) => {
        e.preventDefault();
        // console.log('indexOption', pindex);
        setOption(data.options.length);
        let myObje = { option_al: "", option_en: "", option_sr: "", option_tr: "" };
        let currentState = initialState;
      
        setInitialState(currentState[pindex].options.push(myObje))
        
        setInitialState(currentState);
    }

    const handleOptionsChange = (e, pindex, cindex, type) => {
        // console.log('e.target', e.target.value)
        // console.log('type', type)
        // console.log('cindex', cindex)
        // console.log('pindex', pindex);
        e.preventDefault();
        // let myObje = { option_al: "", option_en: "", option_sr: "", option_tr: "" };
        let currentState = initialState;
        let assign = Object.assign({}, currentState);
        // console.log('optionArray', currentState);
        // console.log('option', initialState);
        if (type === 'oal') {
            assign[pindex].options[cindex].option_al = e.target.value;
        }
        if (type === 'osr') {
            assign[pindex].options[cindex].option_sr = e.target.value;
        }
        if (type === 'oen') {
            assign[pindex].options[cindex].option_en = e.target.value;
        }
        if (type === 'otr') {
            assign[pindex].options[cindex].option_tr = e.target.value;
        }
        setInitialState(currentState);
    }
    //            --  SET QUESTION OPTIONS  --             //

    const submitData = async () => {
        let data = {
            survey: surveyName[0],
            items: initialState,
        };


        await axios
            .post("/api/admin/survey-questions/postBigSurvey", data)
            .then((res) => {
                // console.log(res)
                toast.success('Survey added sucessfully!');
                window.location.assign('/surveys')
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while registering the Survey');
            });
    }

 
    return (
        <>
            <div className="wrapper">

                <header className="mainpage-header">
                    <nav className="navbar pb-4 ps-4 main-items">
                        <h5 className="text-light pt-3 text-center main-title">SURVEYS-*MAIN* ADMINISTRATOR PORTAL</h5>
                    </nav>
                </header>
                <div className="container mt-5 bg-light pb-3">
                    <div className="row pb-3">
                        <h3 className="d-flex left">Emri Pytesorit</h3>
                        <div className="col-3">
                            <p className="mx-auto" style={{ fontWeight: "bold" }} >Shqip</p>
                            <input onChange={(e) => handleSurvey(e, 'al')} className="emriPytsorit form-control" type="text" placeholder="Emri i pytesorit Shqip"></input>
                        </div>
                        <div className="col-3">
                            <p className="mx-auto " style={{ fontWeight: "bold" }}>English</p>
                            <input onChange={(e) => handleSurvey(e, 'en')} className="emriPytsorit form-control" type="text" placeholder="Emri i pytesorit English"></input>
                        </div>
                        <div className="col-3">
                            <p className="mx-auto " style={{ fontWeight: "bold" }}>Serbian</p>
                            <input onChange={(e) => handleSurvey(e, 'sr')} className="emriPytsorit form-control" type="text" placeholder="Emri i pytesorit Serbian"></input>
                        </div>
                        <div className="col-3">
                            <p className="mx-auto " style={{ fontWeight: "bold" }}>Turkish</p>
                            <input onChange={(e) => handleSurvey(e, 'tr')} className="emriPytsorit form-control" type="text" placeholder="Emri i pytesorit Turkish"></input>
                        </div>
                    </div>
                </div>
                {initialState && initialState?.map((el, index) => {
                    // console.log('el', el.options.length)
                    return (
                        <>
                            <div className="container mt-5 ">
                                <div className="row  pb-5 bg-light container">
                                    <div className="col-12 d-flex">
                                        <h3 className="col-sm-11 mt-3 mb-4 d-flex justify-content-left">Question #{index + 1}</h3>
                                        <button className="col-sm-1 btn btn-sm btn-success mt-3 mb-3" onClick={(e) => setOptions(e, index,el)}>Add Option</button>
                                    </div>
                                    <hr />
                                    <div className="col-sm question">
                                        <div className="borderi p-3">
                                            <p className="d-flex left ps-2" style={{ fontWeight: "bold" }}>Albanian</p>
                                            <div className="card-body">
                                                <textarea className="form-control Pytjet-Ne-Survey" rows="4" onChange={(e) => handleQuestion(e, index, 'al')}
                                                    placeholder="Ju lutemi vleresoni me poshte, kenaqesine e sherbimit gjate procesit ne gjykate"></textarea>
                                            </div>
                                        </div>

                                        {el?.options && el?.options?.map((el, cindex) => { return (<input type="text" className="form-control mt-3" placeholder="Albanian" onChange={(e) => handleOptionsChange(e, index, cindex, 'oal')} />) })}
                                    </div>
                                    <div className="col-sm question">
                                        <div className="borderi p-3">
                                            <p className="d-flex left ps-2" style={{ fontWeight: "bold" }}>English</p>
                                            <div className="card-body">
                                                <textarea className="form-control Pytjet-Ne-Survey" rows="4" onChange={(e) => handleQuestion(e, index, 'en')}
                                                    placeholder="Please rate below the satisfaction of the service during the court process?"></textarea>

                                            </div>
                                        </div>
                                        {el?.options && el?.options?.map((el, cindex) => { return (<input type="text" className="form-control mt-3" placeholder="English" onChange={(e) => handleOptionsChange(e, index, cindex, 'oen')} />) })}
                                    </div>
                                    <div className="col-sm question">
                                        <div className="borderi p-3">
                                            <p className="d-flex left ps-2" style={{ fontWeight: "bold" }}>Serbian</p>
                                            <div className="card-body">
                                                <textarea className="form-control Pytjet-Ne-Survey" rows="4" onChange={(e) => handleQuestion(e, index, 'sr')}
                                                    placeholder="Molimo vas da ispod ocenite zadovojlstvo uslugom tokom sudskog procesa?"></textarea>

                                            </div>
                                        </div>
                                        {el?.options && el?.options?.map((el, cindex) => { return (<input type="text" className="form-control mt-3" placeholder="Serbian" onChange={(e) => handleOptionsChange(e, index, cindex, 'osr')} />) })}
                                    </div>
                                    <div className="col-sm question">
                                        <div className="borderi p-3">
                                            <p className="d-flex left ps-2" style={{ fontWeight: "bold" }}>Turkish</p>
                                            <div className="card-body">
                                                <textarea className="form-control Pytjet-Ne-Survey" rows="4" onChange={(e) => handleQuestion(e, index, 'tr')}
                                                    placeholder="merhaba nasisin eyim eyim evet evet?"></textarea>

                                            </div>
                                        </div>
                                        {el?.options && el?.options?.map((el, cindex) => { return (<input type="text" className="form-control mt-3" placeholder="Turkish" onChange={(e) => handleOptionsChange(e, index, cindex, 'otr')} />) })}
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
                }
                <div className="col-12 gap-3 mb-5 mt-5 row">
                    <a className="btn btn-secondary mx-auto mr-5 btn-lg col-sm-3 add-new-question-btn" onClick={setQuestions}>Add New Question</a>
                    <a className="btn btn-secondary mx-auto ms-5 btn-lg col-sm-3 add-question-save " onClick={submitData}>SAVE/UPDATE</a>
                </div>
                {/* <button onClick={submitData}>hello post</button> */}
            </div>
        </>
    );
}
export default AddSurvey;