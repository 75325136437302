import React, { useEffect } from "react";
import "./Users.scss"
import { render } from "@testing-library/react";
import Select from 'react-select';
import { useState } from "react";
import axios from '../../axios';
import AddUser from "./AddUserModal"
import EditUserModal from "./EditUserModal"
import DeleteUserModal from "./DeleteUserModal";
// import Modal from "../portals/SampleModal";

const Users = () => {
    const [state, setState] = useState("")
    const [users, setUsers] = useState([])

      ////////////////////////////////////////////////   
      ///////  State Hooks per hapjen dhe /////////// 
     ////////  manipulimit modalit ////////////////// 
    //////////////////////////////////////////////// 

    const [isOpen, setIsOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);

    const getUsers = () => {
        axios.get("/api/admin/users/all")
            .then(data => {
                // console.log("Users", data.data)
                setUsers(data.data)
            }).catch(err => {
                console.log(err)
            })
    }
 ////////////////////////////////////////////////   
////////  Kontrollimi i hapjes te modalit //////
///////////////////////////////////////////////    
    const renderEditModal = (data) => {
        // console.log('remderFnEditData', data);
        setIsEditOpen(true);
        setDataToModal(data);
    }
    const renderDeleteModal = (data) => {
        // console.log('remderFnData', data);
        setIsDeleteOpen(true);
        setDataToModal(data);
    }
    
    useEffect(() => {
        getUsers();
    }, [])
    // console.log('i fundit user', users[users.length - 1])
    return (
        <>
             {/*  ///////////////////////////////////////////////   
                 ////////  Renderimi i modaleve //////////////// 
                ///////////////////////////////////////////////     */}
            <AddUser getUsers={()=>getUsers()} handleClose={() => setIsOpen(false)} isOpen={isOpen} /> 
            <EditUserModal getUsers={()=>getUsers()} data={dataToModal} handleClose={()=>{setDataToModal(null);setIsEditOpen(false)}} isOpen={ dataToModal && isEditOpen} />
            <DeleteUserModal getUsers={()=>getUsers()} data={dataToModal} handleClose={()=>setIsDeleteOpen(false)} isOpen={ dataToModal && isDeleteOpen} />
            <div className="wrapper">
                <header className="mainpage-header">
                    <nav className="navbar pb-4 ps-4 main-items">
                        <div className="container pe-4 ps-5">
                                {/* <button onClick={() => setIsOpen(true)}>
                                Click to Open Modal
                                </button> */}

                                {/* <Modal handleClose={() => setIsOpen(false)} isOpen={isOpen}>
                                This is Modal Content!
                                </Modal>
                                 */}
                            <h5 className="text-light pt-3 main-title ps-3">Users - *MAIN* ADMINISTRATOR PORTAL</h5>
                            <button className="btn btn-secondary btn-light me-3" onClick={() => setIsOpen(!isOpen)}>Add User < i className="fa ms-1 fa-circle-plus" /></button>
                        </div>
                    </nav>
                </header>
              
 
                {/* <EditUserModal addUser={getUsers} handleClose={() => setIsOpen(false)} isOpen={isOpen} />  */}
                <div className="container">
                    <h5 className="center mt-5">USERS MANAGEMENT MODULE</h5>
                </div>
                {users === null ? <h1 className="mt-5">No Users Found</h1> :
                    <>
                        <div className="row rounded mt-5 border pb-2 pt-2 lista">
                            <div className="col-sm-1">ID</div>
                            {/* <div className="col-sm-1">View</div> */}
                            <div className="col-sm-1">Edit</div>
                            <div className="col-sm-2">delete</div>
                            <div className="col-sm-3">User Name</div>
                            <div className="col-sm-3">Email</div>
                            <div className="col-sm">Location</div>

                        </div>
                    </>}
                {users?.map((el, index) => {
                    return (
                        <>

                            {el.id === 1 ?
                                <div key={index} className="row rounded mt-3 border pt-3 pb-3 listcon" >
                                    <div className="col-sm-1">{el.id}</div>
                                    {/* <div className="col-sm-1"><a role="button ">View <i className="fa-solid fa-eye"></i></a></div> */}
                                    <div className="col-sm-1 "><a style={{ cursor: " not-allowed" }} role="button" disabled> Edit </a><i role="button" className="fa-solid fa-pen-to-square"></i></div>
                                    <div className="col-sm-2 "><a style={{ cursor: " not-allowed" }} role="button" disabled>delete</a> <i className="fa-solid fa-trash"></i></div>
                                    <div className="col-sm-3">{el.full_name}</div>
                                    <div className="col-sm-3">{el.email}</div>
                                    <div className="col-sm">{el.location_id}</div>
                                </div>
                                : <>
                                <div key={index} className="row rounded mt-3 border pt-3 pb-3 listcon" >
                                    <div className="col-sm-1">{el.id}</div>
                                    {/* <div className="col-sm-1"><a role="button ">View <i className="fa-solid fa-eye"></i></a></div> */}
                                    <div className="col-sm-1 hoverViewEdit"><a role="button" onClick={(e) => {e.preventDefault(); renderEditModal(el)}}> Edit </a><i role="button" className="fa-solid fa-pen-to-square"></i></div>
                                    <div className="col-sm-2 delete-location" ><a role="button" onClick={() => renderDeleteModal(el)}>Delete</a> <i className="fa-solid fa-trash"></i></div>
                                    <div className="col-sm-3">{el.full_name}</div>
                                    <div className="col-sm-3">{el.email}</div>
                                    <div className="col-sm">{el.location_id}</div>
                                </div>
                                
                                </>
                            }

                        </>
                    );
                })
                }
            

                {/* <button className="btn btn-secondary btn-lg mt-5 mb-5 add-new-question-btn adduser-btn" onClick={() => render(<AddUser addUser={getUsers} />)}>Add User<i className=" ps-2 fa-solid fa-plus" /></button> */}
            <div className="pb-5"></div>
            </div>
        </>
    );
}
export default Users;