
const auth = () => {
    let token = window.localStorage.getItem('token');
    if(token && token !== "undefined"){
        // console.log('true token', token);
        return true;
    }else{
        // console.log('false token', token);

        return false;
    }
}
export default auth;