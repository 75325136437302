import React from "react";
import { useState, useEffect } from "react";
import "./DeleteWidget.scss"
import axios from '../../../axios';
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";

function DeleteWidget({ data, getWidget, isOpen, handleClose }) {
// console.log('deleteWidget', data)
    useEffect(() => {
      const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
      document.body.addEventListener("keydown", closeOnEscapeKey);
      return () => {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
      };
    }, [handleClose]);
// const DeleteWidget = (props) => {

    const handleDelete = () => {
        axios.delete(`/api/admin/widgets/${data?.id}`)
            .then(data => {
                // console.log("Delete", data.data)
                handleClose();
                toast.success("Widget deleted Sucesfully")
                getWidget();
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
    }


    if (isOpen === true) {
        return (
            <ReactPortal wrapperId="DeleteWidget">
            <div className="wrapper">
                    <div className="delete-user-modal fade-in">
                    <div onClick={()=>handleClose()} className="handleClose"></div>
                        <div className="delete-user-content">
                            <div className="delete-user-header">
                                <h5 className="mx-auto pt-3 pb-3">Delete Widget</h5><h5 className="pt-3 iksiHeader" role="button" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{color :"black" , fontSize:"larger"}}/></h5>
                            </div>
                            <form >
                                <div className="delete-user-body ms-2">
                                    <h4 className="mx-auto d-flex pb-5 justify-content-center">Are you sure you want to delete this Widget?</h4>
                                </div>
                                <div className="row">
                                    <a className="mx-auto col-sm-5 btn btn-success mb-5" onClick={handleDelete}><h4>Yes</h4></a>
                                    <a className=" mx-auto col-sm-5 btn btn-danger mb-5" onClick={() => handleClose()}><h4>No</h4></a>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        );
    } else return null;
}
export default DeleteWidget;