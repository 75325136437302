import React from 'react'
import './loader.scss'
function Loader (){
    return (
        <div className="LoaderContainer">

            <div className="loader"></div>
        </div>
    )
}
export default Loader;
