// import React from "react";
import "../userSettings/EditUserModal.scss"
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from '../../axios';
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import Loader from "../loader/loader";
import ReactPortal from "../portals/ReactPortal";
// const EditUserModal = (children) => {
//     console.log('editchildren', children)



// ndryshimi i modalit nga const ne funkcion
// + parametrat prites si isOpen, handleClose, data te cilat ju kan jap ne Users.js

function EditUserModal({ data, isOpen, handleClose, getUsers }) {
    // console.log('Edit Modal Open data', data);
    const props = data;
    // console.log('editPropsuser', props)
    //////////// Control Esc per mbyllje te modalit

    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    const [shoudLoad, setShouldLoad] = useState(true);


    const [activeUser, setActiveUser] = useState(false);
    const [activeValue, setActiveValue] = useState(activeUser);
    const [locations, setLocations] = useState([]);
    const [locationGroups, setLocationGroups] = useState();
    const [locationId, setLocationId] = useState();
    const [locationGroup, setLocationGroup] = useState();
    const [groupId, setGroupId] = useState();
    const [shouldClose, setShouldClose] = useState(false);

    // console.log('edit modal role', locationId, groupId, locationGroup)

    const handleLocationId = (e) => {
        // console.log('role', e.target.value)
        e.preventDefault()
        setLocationId(e.target.value)
    }
    const handleAssignedlocationID = (e) => {
        // console.log('lokacioni i selektuar', e.target.value)
        e.preventDefault();
        setLocationGroup(e.target.value)
    }
    const handleAssignedgroupID = (e) => {
        // console.log('groupID123', e.target.value)
        e.preventDefault();
        setGroupId(e.target.value)
    }

    let activeValueQeShkon = () => {
        if (activeUser === false) {
            setActiveValue(1)
            setActiveUser(true)
        } else if (activeValue === 1) {
            setActiveUser(false)
            setActiveValue(0)
        };

    }

    const getLocationGroups = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then((data) => {
                setLocationGroups(data.data);
                // console.log('getLocationGroups', data.data)
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    const getLocations = () => {
        axios.get("/api/admin/locations/all")
            .then((data) => {
                setLocations(data.data);
                // console.log('locations', data.data)
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error(err)
            });
    }
    useEffect(() => {
        getLocations();
        getLocationGroups();
        setLocationId(data?.role);
        setLocationGroup(data?.location_id);
        setGroupId(data?.location_id);
    }, [isOpen])
    // console.log('role - locationGroup - groupID', locationId, locationGroup, groupId)

    const EditUser = (e) => {

        e.preventDefault();
        const datauser = {
            id: data?.id,
            full_name: e?.target?.full_name?.value,
            email: e?.target?.email?.value,
            role: e?.target?.role?.value,
            active: activeValue,
            location_id: locationId == 1 ? groupId : locationGroup
        }
        // console.log('hahahihi', datauser)
        axios.put(`/api/admin/users/`, datauser)
            .then(data => {
                // console.log("users edited" + data)
                toast.success("User Edited Sucesfully!")
                getUsers();
                handleClose();
            }).catch(err => {
                console.log(err)
                handleClose();
                toast.error("Something went wrong")
            })

        let oldPass = e.target.oldPass.value;
        let newPass = e.target.newPass.value;
        if (oldPass === "" || oldPass === null) {
            return null;
        }
        else if (oldPass !== "") {
            const passData = {
                oldPass: oldPass,
                pass: newPass
            }
            axios.put(`/api/admin/users/editPassword/${data?.id}`, passData)
                .then(data => {
                    if (data.data === "Please enter correct old password") {
                        toast.error(data.data);
                    }
                    else if (data.data.updated == true) {
                        // console.log("password Changed", data)
                        toast.success("Password changed Sucesfully!")
                    }
                }).catch(err => {
                    console.log('.err', err)
                    toast.error("Something went wrong")
                })
        }

    }
    // console.log('shabaGashi',locationGroup)
    // useEffect(()=>{
    //     if(data && data?.role == 1){
    //         setGroupId(data?.location_id)
    //         console.log('hina')
    //     }
    // },[])


    const lokacioni = locationGroup


    // const lokacioni = locations?.find((el) => el.id === data?.location_id)?.name_al


    setTimeout(() => {
        // console.log('lokacioni, ', data && data?.role)
        if (locationGroup !== undefined) {
            setShouldLoad(false);
        }
    });

    // renderimi me isOpen state

    if (!isOpen) return null;
    return (
        <ReactPortal wrapperId="EditUserModal">
            <div onClick={() => handleClose()} className="handleClose"></div>
            <div className="edit-user-modal fade-in">
                <form onSubmit={EditUser}>
                    <div className="edit-user-content">
                        <div className="edit-user-header mx-auto d-flex">
                            <h5 className="pt-3 pb-2 d-flex mx-auto">Edit User</h5> <h5 className="pt-3 mr-3 xbtn" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                        </div>
                        <h4 className="d-flex left ps-4 mt-2">User Name</h4>
                        <div className=" ps-4 col-sm-11 mb-2">
                            <input required defaultValue={data && data?.full_name} id="full_name" name="full_name" type="text" className="form-control form-control-lg" placeholder="e.g. johndoe" />
                        </div>
                        <h4 className="d-flex left ps-4">Email</h4>
                        <div className=" ps-4 col-sm-11 mb-2">
                            <input required defaultValue={data && data?.email} id="email" name="email" type="email" className="form-control form-control-lg" placeholder="admin@admin.com" />
                        </div>
                        <div className="form-outline ps-4 col-sm-11 mb-2">
                            <h4 className="d-flex left">Password</h4>
                            <input id="oldPass" name="oldPass" type="password" className="form-control oldPassField mb-2" placeholder="Old Password" />
                            < div className="input-group">
                                <input id="newPass" type="password" className="form-control newPassField" placeholder="New Password" />
                                <div className="input-group-append">
                                </div>
                            </div>
                        </div>

                        <h5 className="form-check-label col ps-4 pb-1 pt-1" for="flexCheckDefault">
                            Is Active :
                            <input id="active" onClick={() => activeValueQeShkon(activeUser)} className="form-check-input ms-2 mt-1" type="checkbox" defaultChecked={data && data?.active} />
                        </h5>

                        {shoudLoad === true ? <Loader />
                            :
                            <>
                                <div className="form-outline ps-4 mt-2 col-sm-11 mb-2">
                                    <h4 className="d-flex left">Role</h4>
                                    <select required id="role" defaultValue={data?.role} onChange={handleLocationId} name="role" className="form-select" aria-label="Default select example">
                                        <option value={"1"} >Location Admin (Local Admin)</option>
                                        <option value={"2"} >Mobile</option>
                                        <option value={"3"} >Tablet</option>
                                    </select>

                                </div>
                                <div className="form-outline mx-auto col-sm-11">
                                    {locationId == 1 ?
                                        <>
                                            <h4 className="d-flex left">Change the group of the user</h4>
                                            <select required defaultValue={data && data?.location_id} onChange={handleAssignedgroupID} className="form-select selectDropDown groupWidth" aria-label="Default select example">
                                                <option disabled value={''} hidden>-- Please select a location --</option>
                                                {
                                                    locationGroups?.map(el => {
                                                        // console.log('el',el)
                                                        return (<option value={el.id}>{el.name_al}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </>
                                        :
                                        shoudLoad === true ? <Loader /> : locationId == 3 || locationId == 2 && locationId != undefined ?
                                            <>
                                                <h4 className="d-flex left">Change the  location of the user</h4>
                                                {/* {console.log(locationGroup)} */}
                                                <select required defaultValue={data && data?.location_id} onChange={handleAssignedlocationID} className="form-select selectDropDown groupWidth" aria-label="Default select example">
                                                    <option disabled value={''} hidden>-- Please select a location --</option>
                                                    {
                                                        locations?.map(el => {
                                                            // console.log('el',el)
                                                            return (<option value={el.id}>{el.name_al}</option>
                                                            );
                                                        })
                                                    }

                                                </select>
                                            </>
                                            :
                                            <>
                                                <h4 className="d-flex left">Assign a role to the user</h4>
                                                <select required defaultValue={data && data?.location_id} onChange={handleAssignedgroupID} className="form-select selectDropDown groupWidth" aria-label="Default select example">
                                                    <option disabled value={''}>-- Please select a user Role ! --</option>

                                                </select>
                                            </>
                                    }

                                </div>
                            </>
                        }
                        <i style={{ color: "red" }}>Give acces to this user to one of the services / locations. <br />
                            The user will only be able to edit the survey to the assigned location.
                        </i>
                        <div className="pt-2 pb-2">
                            <button type="submit" className="btn d-flex mx-auto adduser-btn">SAVE/UPDATE</button>
                        </div>
                    </div>
                </form>
            </div >

        </ReactPortal>
    );
}
export default EditUserModal;