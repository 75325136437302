import React from "react";
import { useState , useRef, useEffect } from "react";
import "./CopyWidget.scss";
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";

function CopyWidget({ data, getWidget, isOpen, handleClose }) {

    useEffect(() => {
      const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
      document.body.addEventListener("keydown", closeOnEscapeKey);
      return () => {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
      };
    }, [handleClose]);
// const CopyWidget = (props) => {

    const textAreaRef = useRef(null);

    const copyWidgetArea = (e) => {
        e.preventDefault()
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        handleClose();
        toast.success("widget copied")
      };


    if (isOpen === true) {
        return (
            <ReactPortal wrapperId="CopyWidget">
            <div className="copy-modal">
                    <div onClick={()=>handleClose()} className="handleClose"></div>
                    <div className="copy-modal-content fade-in">
                        <div className="copy-modal-header">
                            <h3 className="pt-3 pb-3 mx-auto">COPY WIDGET SOURCE</h3><h3 className="pt-3 iksiHeader" role="button" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{color :"black" , fontSize:"larger"}}/></h3>
                        </div>
                        <form onSubmit={copyWidgetArea}>
                            {/* <iframe src={`/api/admin/widgets`} frameborder="5"></iframe> */}
                            <textarea ref={textAreaRef} readOnly defaultValue={data?.content} id="content" name="content" cols="56" rows="5" className=" widget-view-area mx-auto d-flex justify-content-center"></textarea>
                        <button type="submit" className="btn btn-success mt-5 mx-auto d-flex justify-content-center">COPY WIDGET</button>
                        </form>

                    </div>
                </div>
            </ReactPortal>
        );
    } else return null;
}
export default CopyWidget;