import React from "react";
import "./viewSurvey.scss"
import axios from '../../../axios';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../../loader/loader";
import ReactPortal from "../../portals/ReactPortal";

function EditSurvey ({ props, getSurveys, isOpen, handleClose }) {
    // console.log('VirewSurvey', props)
    const [ViewModalOpen, setViewModalOpen] = useState(true)
    const [questions, setQuestions] = useState([]);
    const [surveyName, setSurveyName] = useState([]);
    const [editTrigger, setEditTrigger] = useState(false);
    const [shoudLoad, setShouldLoad] = useState(true);


    const LOCATION_ID = window.localStorage.getItem('location_id');

    const getSurveyData = async () => {
        await axios
            .get(`/api/admin/surveys/surveyQuestions/${props.location_id}/${props.id}`)
            .then((data) => {
                setQuestions(data.data);
                if(questions !== []){
                    setShouldLoad(false);
                }
                // console.log('amujte', data.data)
            })
            .catch((err) => {
                console.log("error incoming", err);
                toast.error('There was an issue while getting the Survey');
            });
    }


    //HANDLE SURVEY NAME
    const emriPytesoritObj = useState({ name_al: `${props?.nameAL}`, name_en: `${props?.nameEN}`, name_sr: `${props?.nameSR}`, name_tr: `${props?.nameTR}`, options: `${props?.options}`, location_id: 9999, isDeleted: 0 })
    const handleSurvey = (e, lang) => {
        setSurveyName(emriPytesoritObj);
        e.preventDefault();
        let currentState = emriPytesoritObj;
        if (lang === 'al') {
            let assignal = Object.assign(currentState);
            assignal[0].name_al = e.target.value;
        }
        if (lang === 'en') {
            let assignen = Object.assign(currentState);
            assignen[0].name_en = e.target.value;
        }
        if (lang === 'sr') {
            let assignsr = Object.assign(currentState);
            assignsr[0].name_sr = e.target.value;
        }
        if (lang === 'tr') {
            let assigntr = Object.assign(currentState);
            assigntr[0].name_tr = e.target.value;
        }

    };

    // SET QUESTION
    const addQuestions = () => {
        setQuestions(oldstate => [...oldstate, { question_al: "", question_en: "", question_sr: "", question_tr: "", options: [] }]);
        setSurveyName(emriPytesoritObj);
        // console.log("op", questions?.options)

    }
    const handleQuestion = (e, index, lang) => {
        e.preventDefault();
        // console.log('index123', e)
        let currentState = questions;
        if (lang === 'al') {
            let assignal = Object.assign({}, currentState);
            assignal[index].question_al = e.target.value;
        }
        if (lang === 'en') {
            let assignen = Object.assign({}, currentState);
            assignen[index].question_en = e.target.value;
        }
        if (lang === 'sr') {
            let assignsr = Object.assign({}, currentState);
            assignsr[index].question_sr = e.target.value;
        }
        if (lang === 'tr') {
            let assigntr = Object.assign({}, currentState);
            assigntr[index].question_tr = e.target.value;
        }
    };


    //SET OPTION
    const [option, setOption] = useState([]);
    const addOptions = (e, pindex, data) => {
        e.preventDefault();
        // console.log('indexOption', pindex);
        setOption(data.options.length);
        let myObje = { option_al: "", option_en: "", option_sr: "", option_tr: "" };
        let currentState = questions;
        // console.log('arton', currentState[pindex])
        setQuestions(currentState[pindex].options.push(myObje))
        setQuestions(currentState);
    }

    const handleOption = (e, pindex, cindex, type) => {
        // console.log('e.target', e.target.value)
        // console.log('type', type)
        // console.log('cindex', cindex)
        // console.log('pindex', pindex);
        e.preventDefault();
        // let myObje = { option_al: "", option_en: "", option_sr: "", option_tr: "" };
        let currentState = questions;
        let assign = Object.assign({}, currentState);
        // console.log('optionArray', currentState);
        // console.log('option', initialState);
        if (type === 'oal') {
            assign[pindex].options[cindex].option_al = e.target.value;
        }
        if (type === 'osr') {
            assign[pindex].options[cindex].option_sr = e.target.value;
        }
        if (type === 'oen') {
            assign[pindex].options[cindex].option_en = e.target.value;
        }
        if (type === 'otr') {
            assign[pindex].options[cindex].option_tr = e.target.value;
        }
        setQuestions(currentState);
    }

    useEffect(() => {
        getSurveyData();
    }, [props])

    const handleCloseModal = () => {
        setViewModalOpen(false);
    }

        useEffect(() => {
          const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() && setQuestions('') : null;
          document.body.addEventListener("keydown", closeOnEscapeKey);
          return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
          };
        }, [handleClose]);
    // const g = document.getElementById();
    // for (const i = 0, len = g.children.length; i < len; i++)

    if (isOpen === true) {
        return (
            <ReactPortal>
                <div className="handleClose" onClick={()=>handleClose()}></div>
                <div className="ViewSurvey-header  ">
                    <h5 className="text-light col-sm HeaderTekst">VIEW "{props?.nameAL}" SURVEY</h5>
                    <h5 className="text-light iksiHeader " role="button" onClick={()=>handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                </div>
                <div className="wrapper ViewSurvey">
                    <div className="fullModal pb-2">
                        <div className="mx-auto row pb-3 pt-3" style={{ backgroundColor: "#0ca88921" }}>
                            <div className="container d-flex surveyName">
                                <div className="mt-4 col-sm-2" >
                                    <p className="d-flex left" style={{ fontWeight: "bold" }}>Albanian</p>
                                    <input disabled onChange={(e) => handleSurvey(e, 'al')} className="emriPytsorit form-control" style={{backgroundColor :"#e9ecef"}} type="text" defaultValue={props?.name_al}></input>
                                </div>
                                <div className={editTrigger === true ? " col-sm-2 mt-4 shadowEnable" : " col-sm-2 mt-4 shadowDisable"}>
                                    <p className="d-flex left" style={{ fontWeight: "bold" }}>English</p>
                                    <input disabled onChange={(e) => handleSurvey(e, 'en')} className="emriPytsorit form-control"style={{backgroundColor :"#e9ecef"}} type="text" defaultValue={props?.name_en}></input>
                                </div>
                                <div className={editTrigger === true ? " col-sm-2 mt-4 shadowEnable" : " col-sm-2 mt-4 shadowDisable"}>
                                    <p className="d-flex left" style={{ fontWeight: "bold" }}>Serbian</p>
                                    <input disabled onChange={(e) => handleSurvey(e, 'sr')} className="emriPytsorit form-control"style={{backgroundColor :"#e9ecef"}} type="text" defaultValue={props?.name_sr}></input>
                                </div>
                                <div className={editTrigger === true ? " col-sm-2 mt-4 pb-3 shadowEnable" : " col-sm-2 mt-4 pb-3 shadowDisable"}>
                                    <p className="d-flex left" style={{ fontWeight: "bold" }}>Turkish</p>
                                    <input disabled onChange={(e) => handleSurvey(e, 'tr')} className="emriPytsorit form-control"style={{backgroundColor :"#e9ecef"}} type="text" defaultValue={props?.name_tr}></input>
                                </div>
                                {/* <div className="buttoniEdit col mt-5 d-flex mx-auto">
                                <i className="fa fa-edit"></i>
                            </div>
                            <div className="buttoniDelete col mt-5 d-flex mx-auto">
                                <i className="fa fa-trash"></i>
                            </div> */}
                            </div>
                        </div>

                        {shoudLoad === true ? <Loader /> : questions && questions?.map((el, index) => {
                            // console.log('el', el, index)
                            return (
                                <>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-12 row pt-5">
                                                <h3 className="col-sm-6 d-flex row left ms-1">Question #{index + 1}</h3>
                                            </div>
                                            <hr />
                                            <div className=" gap-3 d-flex pb-2"   >
                                                {/* {deleteTrigger === true ? <p className="doYouReallyWantToDelete">Are you sure you want to delete this question?</p>: ''} */}

                                                <div className={editTrigger === true ? " col-sm shadowEnable" : " col-sm shadowDisable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Albanian</p>
                                                        <textarea disabled={(editTrigger === true) ? "" : "disabled"} style={{ color: "grey" }} className="Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'al')}
                                                            defaultValue={el.question_al}></textarea>
                                                    </div>
                                                    {el?.options && el?.options?.map((el, cindex) => { return (<><hr className="HrDashStyle" /><input style={{backgroundColor :"#e9ecef"}} disabled={(editTrigger === true) ? "" : "disabled"} type="text" className="form-control mt-5" placeholder="Albanian" defaultValue={el.option_al} onChange={(e) => handleOption(e, index, cindex, 'oal')} /></>) })}

                                                    <div>
                                                    </div>
                                                </div>
                                                <div className={editTrigger === true ? " col-sm shadowEnable" : " col-sm shadowDisable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">English</p>
                                                        <textarea disabled={(editTrigger === true) ? "" : "disabled"} style={{ color: "grey" }} className="  Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'en')}
                                                            defaultValue={el.question_en}></textarea>
                                                    </div>
                                                    {el?.options && el?.options?.map((el, cindex) => { return (<><hr className="HrDashStyle" /><input style={{backgroundColor :"#e9ecef"}} disabled={(editTrigger === true) ? "" : "disabled"} type="text" className="form-control mt-5" placeholder="English" defaultValue={el.option_en} onChange={(e) => handleOption(e, index, cindex, 'oen')} /></>) })}

                                                    {/* <input disabled type="text" className="form-control mt-3" placeholder="test" onChange={(e) => handleOption(e, 'en')}/> */}
                                                </div>
                                                <div className={editTrigger === true ? " col-sm shadowEnable" : " col-sm shadowDisable"}  >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Serbian</p>
                                                        <textarea disabled={(editTrigger === true) ? "" : "disabled"} style={{ color: "grey" }} className=" Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'sr')}
                                                            defaultValue={el.question_sr}></textarea>
                                                    </div>
                                                    {el?.options && el?.options?.map((el, cindex) => { return (<><hr className="HrDashStyle" /><input style={{backgroundColor :"#e9ecef"}} disabled={(editTrigger === true) ? "" : "disabled"} type="text" className="form-control mt-5" placeholder="Serbian" defaultValue={el.option_sr} onChange={(e) => handleOption(e, index, cindex, 'osr')} /></>) })}

                                                    {/* <input disabled type="text" className="form-control mt-3" placeholder="test" onChange={(e) => handleOption(e, 'sr')}/> */}
                                                </div>
                                                <div className={editTrigger === true ? " col-sm shadowEnable" : "col-sm shadowDisable"} >
                                                    <div className="borderi p-3">
                                                        <p className="left d-flex ms-1 gjuhet">Turkish</p>
                                                        <textarea disabled={(editTrigger === true) ? "" : "disabled"} style={{ color: "grey" }} className="Pytjet-Ne-Survey form-control" rows="4" onChange={(e) => handleQuestion(e, index, 'tr')}
                                                            defaultValue={el.question_tr}></textarea>
                                                    </div>

                                                    {el?.options && el?.options?.map((el, cindex) => {
                                                        return (<>
                                                            <hr className="HrDashStyle" />
                                                            <input style={{backgroundColor :"#e9ecef"}} disabled={(editTrigger === true) ? "" : "disabled"} type="text" className="form-control mt-5" placeholder="Turkish" defaultValue={el.option_tr} onChange={(e) => handleOption(e, index, cindex, 'otr')} />
                                                        </>
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>
                </div>
            </ReactPortal>
        );
    }
    else {
        return null;
    }
}
export default EditSurvey;