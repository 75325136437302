import axios from "axios";

let token = window.localStorage.getItem("token");

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API,
  headers: {
    Authorization: "Bearer " + token,
    
  },
});

export default defaultInstance;