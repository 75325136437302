import React from "react";
import "./Mainpage.scss"
import { render } from '@testing-library/react';
import { useState } from "react";
import Servicesicon from "../../images/Services.png";
import Surveysicon from "../../images/Survey.png";
import Usersicon from "../../images/users.png";
import Widgeticon from "../../images/widget.png";
import { Link } from "react-router-dom";
import Select from 'react-select';
import AddService from "../servicesLocations/addLocation/addLocation"
import axios from "../../axios";
import { useEffect } from "react";
import ChangePasswordModal from "./changePassword/ChangePassword";


const MainPage = (props) => {

    const [groupLocations, setGroupLocations] = useState([])
    const [locations, setLocations] = useState([])

    const handlelocationId = (e) => {
        window.location.href = `/addSurvey/${(e?.target?.value).replace(",","/")}`    }

    const getLocations = () => {
        axios.get('/api/admin/locations/all')
            .then(data => {
                // console.log("locations", data.data)
                setLocations(data.data)
            }).catch(err => {
                console.log(err)
            })
    }

    const getGroupLocations = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then(data => {
                // console.log("group locations", data.data)
                setGroupLocations(data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    
    

    const handeleLogOut = () => {
        window.localStorage.clear();
        window.location.assign('/');
    }
    const location_id = window.localStorage.getItem('location_id');
    // console.log('location_id', location_id)
    
    const [isOpen, setIsOpen] = useState(false);
    const [dataToModal, setDataToModal] = useState(null);

    useEffect(() => {
        getGroupLocations();
        getLocations();
    }, [])
    console.log("isopen" , isOpen);

    return (
        <>
        <ChangePasswordModal handleClose={()=> setIsOpen(false)} isOpen={isOpen} />
            <div className="wrapper">
                <header className="mainpage-header">
                    <nav className="navbar pb-4 main-items">
                        <div className="container ms-5">
                            <h5 className="text-light mx-auto pt-3">*MAIN* ADMINISTRATOR PORTAL</h5>
                            <div className="d-flex gap-5">
                                {location_id != 9999 ?
                                  <button className="btn btn-sm-5 btn-secondary main-buttons" onClick={()=> setIsOpen(true)}>Change Password<i class=" ms-4 fa-solid fa-key"></i></button>
                                 :
                                 ""
                                 }
                                <button className="btn btn-sm-5 btn-secondary main-buttons" onClick={handeleLogOut}>Sign out
                                    <i className="fa-solid fa-arrow-right-from-bracket ps-2"></i>
                                </button>
                                {location_id == 9999 ?
                                    <div className="col">
                                        <select key={"index"} className="form-control mt-2" onChange={handlelocationId}>
                                            <option disabled selected hidden>Add Survey To Location</option>
                                            {locations.map((el) => {
                                                return (
                                                    <>
                                                        <option value={[el.id , el.group_id]}>{el.name_al}</option>
                                                    </>
                                                )
                                            })}
                                        </select>
                                    </div>
                                    :
                                    ""
                                }

                                {/* <Select
                                placeholder="Select Group Locations"
                                className="mt-3"
                                options={groupLocations.map((el) => ({value: el.id, label: el.name_al }))}
                            /> */}
                            </div>
                        </div>
                    </nav>
                </header>
                            <div className="card col-2 mx-auto mt-3">
                                <Link className="main-text" to={"/surveyAnswers"}>
                                    <div className="card-body">
                                        <h5 className="main-text">SURVEY ANSWERS</h5>
                                        <i class="fa-regular fa-folder" style={{fontSize:"30px" , color:"black"}}></i>
                                    </div>
                                </Link>
                            </div>
                <div className="row gap-3 d-flex justify-content-center mt-5 mb-5">
                    {location_id == 9999 ?
                        <div className="card col-6 col-sm-4">
                            <Link className="main-text" to={"/services"} >
                                <img src={Servicesicon} alt="Services" className="main-icon mt-3 mb-3" />
                                <h4 className="main-text mb-2">SERVICES/LOCATIONS</h4>
                            </Link>

                        </div>
                        :
                        ""
                    }
                    <div className="card col-6 col-sm-4">
                        <Link className="main-text" to={"/surveys"}>
                            <div className="card-body">
                                <img src={Surveysicon} alt="surveys" className="main-icon mt-1 mb-3" />
                                <h4 className="main-text">SURVEYS</h4>
                            </div>
                        </Link>
                    </div>

                    <div className="w-100"></div>

                    {location_id == 9999 ?
                        <div className="card col-6 col-sm-4">
                            <Link className="main-text" to={"/users"}>
                                <div className="card-body">
                                    <img src={Usersicon} alt="Users" className="main-icon mt-1 mb-3" />
                                    <h4 className="main-text">ADD/EDIT/DELETE USERS</h4>
                                </div>
                            </Link>
                        </div>
                        :
                        ""
                    }
                    {location_id == 9999 ?
                        <div className="card col-6 col-sm-4">
                            <Link className="main-text" to={"/widgets"}>
                                <div className="card-body">
                                    <img src={Widgeticon} alt="widget" />
                                    <h4 className="main-text">WIDGETS</h4>
                                </div>
                            </Link>
                        </div>
                        :
                        ""
                    }
                </div>
            </div>
            <div>
            </div>
        </>
    );
}
export default MainPage;