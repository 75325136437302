import React, { useState, useEffect } from "react";
import "./addLocation.scss";
import axios from '../../../axios';
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";

// const AddLocation = (props) => {
function AddLocation({ props, isOpen, handleClose }) {


    //////////// Control Esc per mbyllje te modalit
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);
    const [serviceModal, setServiceModal] = useState(true)
    const [grouplocations, setGroupLocations] = useState([]);
    const [groupLocationId, setgroupLocationId] = useState()

    const handleGroupLocationid = (e) => {
        e.preventDefault()
        setgroupLocationId(e.target.value)
    }
    const getGroupLocation = () => {
        axios.get("/api/admin/locations-grouping/all")
            .then(data => {
                // console.log("group locations", data.data)
                setGroupLocations(data.data)
            }).catch(err => {
                console.log(err)
            })
    }
    useEffect(() => {
        getGroupLocation()
    }, [])
    const submitLocation = async (e) => {
        e.preventDefault();
        let locationData = {
            name_al: e?.target.name_al.value,
            name_en: e?.target.name_en.value,
            name_sr: e?.target.name_sr.value,
            name_tr: e?.target.name_tr.value,
            group_id: groupLocationId
        };
        axios
            .post("/api/admin/locations", locationData)
            .then((res) => {
                // console.log('locationResponse', res)
                toast.success('Sucessfully added location!');
            })
            .catch((err) => {
                console.log("error", err);
                toast.error('There was an error on adding the location!');
            });
    }
    const handleCloseLocation = () => {
        setServiceModal(false)
    }
    if (isOpen === true) {
        return (
            <ReactPortal wrapperId="AddLocationModal">
                <div className="wrapper " >
                    <div className="Service-modal fade-in" >
                        <div className="handleClose" onClick={() => handleClose()} ></div>
                        <div className="Service-content ">
                            <form onSubmit={submitLocation}>
                                <div className="Service-header ">
                                    <h5 className="mx-auto ps-4 pt-3 pb-3 mt-1">ADD NEW Service</h5><h5 className="pt-3 d-flex xService-btn" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name AL</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_al" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name EN</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_en" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name SR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_sr" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Location Name TR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_tr" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Parent Of :</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <select onChange={handleGroupLocationid} className="form-control form-control-lg">
                                        {grouplocations.map(el => {
                                            return (
                                                <option value={el.id}>{el.name_al}</option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <button type="submit" className="btn mx-auto d-flex Service-btn mb-5"><h4>SAVE/UPDATE</h4></button>
                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        );
    } else return null;
}
export default AddLocation;