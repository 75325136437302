import React from "react";
import { useState , useEffect } from "react";
import axios from '../../axios';
import { toast } from "react-toastify";
// import { Navigate } from "react-router-dom";
import { redirect, useNavigate } from 'react-router-dom';
import ReactPortal from "../portals/ReactPortal";


function DeleteSurvey ({data , getSurveys, isOpen , handleClose}) {
    // console.log('delete data',data)
    // const navigate = useNavigate();
    // console.log('deleteProps',props)
    const [deletemodal, setDeletemodal] = useState(true)
    const handleDelete = () =>{
        axios.delete(`/api/admin/surveys/${data?.id}`)
        .then(res => {
            // console.log("delete user" , res.props)
            handleClose();
            getSurveys();
            // redirect('/surveys')
            toast.success("Survey deleted sucessfully!")
        }).catch(err => {
            console.log("error" , err)
            toast.error("Something went wrong deleting survey.")
        })

    }
    const handleCloseModal = () => {
        setDeletemodal(false);
    }

        //////////// Control Esc per mbyllje te modalit
        useEffect(() => {
            const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
            document.body.addEventListener("keydown", closeOnEscapeKey);
            return () => {
              document.body.removeEventListener("keydown", closeOnEscapeKey);
            };
          }, [handleClose]);

    if (!isOpen) return null;
        return (
            <ReactPortal wrapperId="DeleteSurvey">
            <div className="wrapper">
                <div className="delete-user-modal fade-in">
            <div className="handleClose" onClick={() => handleClose()}></div>
                    <div className="delete-user-content">
                        <div className="delete-user-header d-flex">
                            <h5 className="pt-3 mx-auto">Delete Survey</h5>
                            <h5 className="xwiget-btn pt-3" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{color :"black" , fontSize:"larger"}}></i></h5>
                        </div>
                        <form >
                            <h4 className=" pt-5 d-flex justify-content-center mx-auto tekstiNdelete ">Are you sure you want to delete "{data?.name_al}" Survey?</h4>
                            <div className="d-flex mx-auto justify-content-center delete-user-btn pb-5">
                                <a className="btn btn-success butonatDelete" onClick={() => handleDelete()}>Yes</a>
                                <button className="btn btn-danger butonatDelete ms-2" onClick={() => setDeletemodal(false)}>No</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </ReactPortal>
        );
    }
export default DeleteSurvey;