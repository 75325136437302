import React from "react";
import "./EditLocationGroup.scss";
import { useState , useEffect } from "react";
import axios from "../../../axios";
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";
function AddGroupLocationModal ({props , isOpen , handleClose}) {
    // console.log("gredit" , props)
    const handleEditGroup = (e) => {
        e.preventDefault()
        let editGroupData = {
            id: props?.id,
            name_al : e?.target?.name_al.value,
            name_en : e?.target?.name_en.value,
            name_sr : e?.target?.name_sr.value,
            name_tr : e?.target?.name_tr.value
        }
        axios.put("/api/admin/locations-grouping/",editGroupData)
        .then(data => {
            // console.log("UPDATE GROUP DATA" , data)
            toast.success("Group location edited successfully")
        }).catch(err => {
            console.log(err)
            toast.error("Something went wrong")
        })
    }


    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);

    if (isOpen === true) {
        
        return (
            <ReactPortal>
                <div className="wrapper " >
                    <div className="Service-modal fade-in " >
                        <div className="handleClose" onClick={() => handleClose()}></div>
                        <div className="Service-content ">
                            <form onSubmit={handleEditGroup}>
                                <div className="Service-header ">
                                    <h5 className="mx-auto pt-3 pb-3">Edit group locations</h5><h5 className="xwidget-btn pt-3" role="button" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{color :"black" , fontSize:"larger"}}/></h5>
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name AL</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_al" defaultValue={props?.name_al} required />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name EN</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_en" defaultValue={props?.name_en} required />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name SR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_sr" defaultValue={props?.name_sr} required />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name TR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input type="text" className="form-control form-control-lg" name="text" id="name_tr" defaultValue={props?.name_tr} required />
                                </div>
                                    <button type="submit" className="btn btn-sm Service-btn mb-5 mx-auto d-flex">UPDATE GROUP</button>
                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        )
    } else return null;
}
export default AddGroupLocationModal;