import React, { useEffect } from "react";
import "./AddGroupLocation.scss";
import { useState } from "react";
import axios from "../../../axios";
import { toast } from "react-toastify";
import ReactPortal from "../../portals/ReactPortal";

// const AddGroupLocationModal = (props) => {
function AddGroupLocationModal({ props, isOpen, handleClose }) {


    //////////// Control Esc per mbyllje te modalit
    useEffect(() => {
        const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
        document.body.addEventListener("keydown", closeOnEscapeKey);
        return () => {
            document.body.removeEventListener("keydown", closeOnEscapeKey);
        };
    }, [handleClose]);
    const [addGroupLocationModal, setAddGroupLocationModal] = useState(true);

    const SubmitGroupLocation = (e) => {
        e.preventDefault();
        let groupdata = {
            name_al: e?.target?.name_al.value,
            name_en: e?.target?.name_en.value,
            name_sr: e?.target?.name_sr.value,
            name_tr: e?.target?.name_tr.value
        }
        axios.post("/api/admin/locations-grouping/", groupdata)
            .then(data => {
                // console.log("Add Group" , data.data)
                toast.success("Group location added successfully")
            }).catch(err => {
                console.log(err)
                toast.error("Something went wrong")
            })
    }

    const handleCloseModal = () => {
        setAddGroupLocationModal(false)
    }

    if (isOpen === true) {

        return (
            <ReactPortal wrapperId="addGroup">
                <div className="wrapper " >
                    <div className="Service-modal fade-in" >
                        <div className="handleClose" onClick={()=>handleClose()}></div>
                        <div className="Service-content ">
                            <div className="Service-header mx-auto d-flex justify-content-center">
                                <h5 className="mx-auto ps-4 pt-3 pb-3 mt-1">Add new group location</h5><h5 className="pt-3 d-flex xService-btn" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                            </div>
                            <form onSubmit={SubmitGroupLocation}>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name AL</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input required type="text" className="form-control form-control-lg" name="text" id="name_al" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name EN</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input required type="text" className="form-control form-control-lg" name="text" id="name_en" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name SR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input required type="text" className="form-control form-control-lg" name="text" id="name_sr" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <h5 className="d-flex left ps-4 ms-2">Group location Name TR</h5>
                                <div className=" ps-4 col-sm-11 mb-4 ms-2">
                                    <input required type="text" className="form-control form-control-lg" name="text" id="name_tr" placeholder="e.g Basic Court Prishtina" />
                                </div>
                                <button type="submit" className="btn d-flex Service-btn mb-5 mx-auto"><h4>ADD GROUP</h4></button>
                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        )
    } else return null;
}
export default AddGroupLocationModal;