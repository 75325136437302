import React from "react";
import "./DeleteLocationGroup.scss"
import axios from "../../../axios";
import { useState, useEffect } from "react";
import {toast} from "react-toastify";

// const DeleteGroupLocation = (props) => {
    function DeleteGroupLocation({ props, isOpen, handleClose }) {


        //////////// Control Esc per mbyllje te modalit
        useEffect(() => {
            const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
            document.body.addEventListener("keydown", closeOnEscapeKey);
            return () => {
                document.body.removeEventListener("keydown", closeOnEscapeKey);
            };
        }, [handleClose]);

    const [deleteGroupModal , setDeleteGroupModal] = useState(true)

    const handleDeleteGroup = () => {
        axios.delete(`/api/admin/locations-grouping/${props}`)
        .then(data => {
            // console.log("Delete Location" , data.data)
            toast.success("Group location deleted successfully")
        }).catch(err =>{
            console.log(err)
            toast.error("Something went wrong")
        })
    }
    const handleCloseModal = () => {
        setDeleteGroupModal(false)
    }

    if(isOpen === true) {

        return(
            <>
                    <div className="delete-user-modal fade-in">
          <div className="wrapper handleClose" onClick={()=> handleClose()}>    
          </div>
                        <div className="delete-user-content">
                            <div className="delete-user-header d-flex">
                                 <h5 className="pt-3 mx-auto ">Delete Group Location</h5><h5 className="xwiget-btn pt-3" onClick={() => handleClose()} ><i className="fa-solid fa-xmark" style={{color :"black" , fontSize:"larger"}}/></h5>
                            </div>
                            <form >
                                <div className="delete-user-body ms-2">
                                    <h4 className="d-flex pt-5 ms-4 pb-4 left ps-4">Are you sure you want to delete this group location?</h4>
                          </div>
                          <div className="d-flex mx-auto justify-content-center delete-user-btn pb-5">
                                <a className="btn btn-success butonatDelete" onClick={handleDeleteGroup}><h4>Yes</h4></a>
                                <a className="btn btn-danger butonatDelete ms-2" onClick={() => handleClose()}><h4>No</h4></a>
                                </div>

                            </form>
                        </div>
                    </div>
                        
        </>
    )
}else return null
}
export default DeleteGroupLocation