import React from "react";
import { useState, useEffect } from "react";
import "./DeleteUserModal.scss"
import axios from '../../axios';
import { toast } from "react-toastify";
import ReactPortal from "../portals/ReactPortal";



// ndryshimi i modalit nga const ne funkcion
// + parametrat prites si isOpen, handleClose, data te cilat ju kan jap ne Users.js

// const DeleteUserModal = (data) => {
    // console.log('datandelete', data)
    // const [deletemodal, setDeletemodal] = useState(true)

function DeleteUserModal({ getUsers, data, isOpen, handleClose }) {
    // console.log('Delete Modal Open', data );

    //////////// Control Esc per mbyllje te modalit

    useEffect(() => {
      const closeOnEscapeKey = e => e.key === "Escape" ? handleClose() : null;
      document.body.addEventListener("keydown", closeOnEscapeKey);
      return () => {
        document.body.removeEventListener("keydown", closeOnEscapeKey);
      };
    }, [handleClose]);
    const handleDelete = () => {
        axios.delete(`/api/admin/users/${data?.id}` )
            .then(data => {
                // console.log("delete user", data.data)
                // setDeletemodal(false);
                toast.success("User successfully deleted !");
                handleClose();
                getUsers();
            }).catch(err => {
                console.log("err", err);
                toast.error("Something went wrong");
            })

    }
    const handleCloseModal = () => {
        handleClose();
    }


    // renderimi me isOpen state
    if (!isOpen) return null;
    return (
      <ReactPortal wrapperId="DeleteUserModal">
                <div className="wrapper">
                    <div className="delete-user-modal fade-in">
                    <div onClick={()=>handleClose()} className="handleClose"></div>
                        <div className="delete-user-content">
                            <div className="delete-user-header d-flex">
                                <h5 className="pt-3 mx-auto">Delete User</h5>
                                <h5 className="xwiget-btn pt-3" onClick={() => handleClose()}><i className="fa-solid fa-xmark" style={{ color: "black", fontSize: "larger" }} /></h5>
                            </div>
                            <form >

                                <h4 className="pt-5 d-flex justify-content-center mx-auto tekstiNdelete ">Are you sure you want to delete {data?.full_name} as a  user?</h4>

                                <div className="d-flex mx-auto justify-content-center delete-user-btn pb-5">
                                    <button className="btn bg-success butonatDelete" style={{color:"white"}} onClick={(e) =>(e.preventDefault(), handleDelete())}>Yes</button>
                                    <button className="btn btn-danger butonatDelete ms-2" onClick={() => handleClose()}>No</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </ReactPortal>
        );
}
export default DeleteUserModal;